import React from 'react';
import './Faq.scss';
import ContentTemplate from "../ContentTemplate/ContentTemplate";
import Constants from "../Constants";
import {HashLink as Link} from "react-router-hash-link";
import imgUhOh from "../../assets/images/uh-oh-error-img.png";
import {Table} from "react-bootstrap";

interface Props {
}

interface State {
}

export default class Faq extends React.Component<Props, State> {

    private sideNavigationLinks: Map<string, string> = new Map([
        ["#dealer", "Dealer"],
        ["#consumer", "Consumer"]
    ]);

    private navLinksMap: Map<string, Map<string, string>> = new Map ([
        ["Frequently Asked Questions (FAQ)", this.sideNavigationLinks]
    ]);

    public render() {
        return (
            <div className={"faq"}>
                <ContentTemplate
                    pageTitle="Frequently Asked Questions (FAQ)"
                    navlinksMap={this.navLinksMap}
                    content={this.getContentHtml()}
                />
            </div>
        );
    }

    private getContentHtml(): JSX.Element {
        return (
            <div className={"content-container"}>
                <h1>Frequently Asked Questions (FAQ)</h1>
                <section>
                    <h3 id={"dealer"}>Dealer</h3>
                    <div className="question">
                        Q. How do I ensure dealer users only have to refresh credentials every 90 days or if they do not
                        use CARFAX for 30 consecutive days?
                    </div>
                    <div className="answer">
                        A. A hard refresh will prompt the user to log in again when the user's session expires after 90
                        days. To prompt users to log in again who do not use CARFAX for 30 consecutive days do the following:
                        <ul>
                            <li>Account for retrieving a refresh token value in the JSON response of an access token request.</li>
                            <li>
                                The refresh token duration is still 30 days but may only be used one time to fetch an access token.
                                Access tokens are valid for 24 hours and may be used multiple times during their valid duration.
                            </li>
                            <li>
                                Since refresh token can only be used once to fetch an access token, if you have been
                                saving refresh tokens in a database for a given user, you will need to replace the
                                saved <strong>old</strong> refresh token with the <strong>new</strong> refresh token you
                                will get from your access token Request.
                            </li>
                        </ul>
                        Existing partners or those testing will need to make the update to fetch a refresh token with
                        each access token request. If you do not make this update then the existing refresh tokens will
                        expire in 30 days and users will be directed to log in until the 90 day mark at which point
                        they’ll be prompted again. We recommend creating a new Client ID in the Partner Portal.
                        The new 90 day refresh cadence will be applied to this new Client ID which you may swap for your
                        current Client ID after testing. Once updated please alert your account manager.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. I've had features in my application that historically were offered to only Advantage dealers.
                        How can I identify if a user is associated with an Advantage dealer?
                    </div>
                    <div className={"answer"}>
                        A. You will no longer need to identify Advantage dealers. Connect automatically associates the
                        user to the respective dealer and responds with only those features that are available when queries
                        are made. For example, an Advantage dealer using Connect in an Enhanced partner tool will have the
                        ability to utilize all 4 Pillars of CARFAX data and these are returned when 4 Pillars is queried.
                        To provide features such as automatically running a report when a VIN is entered in your application
                        for Advantage dealers please follow the instructions found on the top of the&nbsp;
                        <Link to={Constants.DEALER_REPORT_PAGE_URL}>Dealer Partner page
                        </Link> which details how to use the dealerReport query in combination with the purchaseDealerReportIfRequired
                        mutation.

                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. I'm not getting the new Refresh Token each time I make a token request. Why?
                    </div>
                    <div className={"answer"}>
                        A. To get a new Refresh Token on each token request, you must have a Client ID set up after
                        February 5, 2021. If you are partner who went live with Connect prior to this or was testing with
                        an existing Client ID you will need to set up a new Client ID in the Partner Portal in order to
                        have the new Refresh Token returned on each request. See the question above for more details.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. How often will users be asked to re-enter their credentials in my application?
                    </div>
                    <div className={"answer"}>
                        A. For security purposes users will be asked to refresh their credentials every 90 days but this
                        is on a per device basis. That is, if a user resaves their credentials on day 90 in Partner A
                        but was also using CARFAX Connect in Partner B and C, Partner B and C will not ask the user to resave
                        credentials but instead will simply provide a Welcome Back message the next time the user engages
                        with Connect. As a reminder, a user will need to resave credentials or confirm the Welcome Back
                        message in your application wherever it is used which means they may be prompted to do so on a
                        desktop browser and mobile application.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. If a user is logged into two separate browsers on the same machine, do I need to use separate
                        refresh tokens for each browser, or should I use only one token for that user/device?
                    </div>
                    <div className={"answer"}>
                        A. The refresh and access tokens are specific to the user on a device/browser basis. In this case
                        a separate refresh token would need be used for each browser on the same machine the user accesses.
                        This use case is likely rare but demonstrates the need for unique refresh tokens to be used on each
                        device such as a desktop browser and mobile device.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. Do I need to authenticate users at a user or dealer level?
                    </div>
                    <div className={"answer"}>
                        A. One of the key benefits of CARFAX Connect is to enable Single Sign-On for users across all partner applications
                        on the same device.  As we pivot users from a shared store level login to user based logins we are striving to minimize disruption.  SSO whereby a user logs in to
                        the first of three applications on his desktop for example will enable the user to more easily run reports on the other two applications as well as to seamlessly
                        keep running reports when he refreshes credentials in the future on just one application.  To enable this feature when a user logs in the first time you should
                        store the Refresh token in your database per user per device (device can be mobile or separate browser on the same machine) and store the Access token in an HttpOnly,
                        Secure cookie or in the web server session.  Note that the Access token for the most recently logged in user at a given dealership may be stored in your database for
                        use in refreshing 4 Pillar data overnight but should only be used for updating inventory in cache and not used to enable other users to run reports who have not yet
                        authenticated.  See the <Link to={Constants.SSO_IMPLEMENTATION_URL}>SSO page</Link> for more details.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. What do I need to have in place prior to doing a demo review session with CARFAX as I prepare to launch Connect in my application?
                    </div>
                    <div className={"answer"}>
                        A. As a general guide, please have the following key items in place such that they can be reviewed in a demo on both your desktop and mobile applications where applicable:

                        <ol>
                           <li> Show Log In/Sign Out settings screen and demonstrate a first time login experience.</li>
                           <li>Ability to run/view a CARFAX Report for an Advantage and non-Advantage dealer using the test credentials assigned.</li>
                           <li>If integrated, show 4/2 Pillar data points and Snapshot.</li>
                           <li>Verify all references to CARFAX are in all capital letters and the proper logo and icons are using per the <Link to={`${Constants.DEALER_PAGE_URL}#carfax-branding`}>Branding page</Link>.</li>
                           <li>Confirm that each user is authenticated via Connect individually per the FAQ above.</li>
                           <li>If caching 4 Pillar data demonstrate how it is used across other users at the same store.  Indicate how long you are keeping the data cached (1-7 days).</li>
                           <li>Show Log Out flow where all CARFAX data is removed for the user, tokens are revoked and the user is redirected to the login screen.</li>
                           <li>Demonstrate error handling and messages presented to dealers
                               <ul className={"add-padding"}>
                                   <li>No data VIN - JA4MT21H13J099025, 1GDM7H1C4YJ586577</li>
                                   <li>Invalid VIN - 1QTRW08L62KE68925, 3O4FY48BX6T282654</li>
                                   <li>No Report in Inventory - May render for a non-Advantage dealer in lieu of Snapshot when a report has not yet been run.</li>
                                   <li>Invalid Account - A select test account may be suspended temporarily to demonstrate this.</li>
                               </ul>
                           </li>
                           <li>Review user experience when Refresh token expires and the user is prompted to enter credentials again.
                               This case may not be demonstrable.</li>
                            <li>Confirm that the Client ID being used is configured to allow for a refresh token to be used one time
                                to fetch a new access/refresh token and the user's session is set at 90 days.</li>
                        </ol>
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. What is the performance SLA for Connect?
                    </div>
                    <div className={"answer"}>
                        A. Average response time is 250ms with a maximum of 500ms for single queries.  Batch requests
                        will have a higher response time and will vary depending on how many VINs are requested.
                        Note that to scale for large request volumes you may use a guideline of 250 requests per second,
                        burst 250 requests per second, with a cap of 4,000,000 per day.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. Do I need to have a Sign Out button?
                    </div>
                    <div className={"answer"}>
                        A. Yes, we ask that you please add a Sign Out button/link in your application.  This feature is to allow for cases where CARFAX may be accessed via your
                        application on a shared machine where the user may sign out prior to logging out of your application.  It also facilitates possible future bug resolution
                        where a user must sign out to reset tokens.  Details on how to code this Sign Out button may be found on the <Link to={`${Constants.AUTHENTICATION_PAGE_URL}#log-out`}>Log Out page</Link>.
                        Note that when a user logs out all CARFAX report links and 4 Pillar data should be removed for the user although the data may remain in your cache to facilitate
                        loading for other users at the same dealership.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. Do I need to log out a CARFAX user when they log out of my application?
                    </div>
                    <div className={"answer"}>
                        A. No, users may remain logged in to facilitate the ease of running CARFAX reports the next time one logs in to your application.
                        You will simply continue to use the Refresh token to fetch a new Access token the next time the user attempts to run a report.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. Is Audience a required parameter when fetching a new Access token using the Refresh token?
                    </div>
                    <div className={"answer"}>
                        A. No, we have recently deprecated the Audience parameter to align to the standard OAuth2 format.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. Do I need to support legacy CARFAX usernames and Connect in my application simultaneously?
                    </div>
                    <div className={"answer"}>
                        A. This will vary based on the number of dealers that will need to migrate over to using email usernames via Connect.
                        Smaller partners with dozens of CARFAX dealers may simply cut over to Connect on a mutually agreed to date.
                        For larger partners it is preferable to migrate dealers in phases to avoid a large influx of possible support calls.
                        Doing so means supporting the legacy usernames for a period of time along side Connect.  This can take the form of doing a hard
                        cutover of small groups of dealers on given dates to Connect who would only use Connect while all other dealers remain on the legacy usernames for example.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. Why am I getting an Unauthorized error when attempting to run a dealerReport query?
                    </div>
                    <div className={"answer"}>
                        A. You may be using the Client Credentials Flow, instead use the <Link to={`${Constants.AUTHENTICATION_PAGE_URL}#authorization-code-flow`}>Authorization Code Flow</Link>. Also ensure you're using the
                        correct grant_type - <code>authorization_code</code>.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. Why am I getting an Unauthorized error when using Authorization Code Flow?
                    </div>
                    <div className={"answer"}>
                        A. Ensure you are sending the Access Token in <code>Authorization</code> HTTP header. The header value should look like <code>Bearer &lt;access_token&gt;</code>. Verify that you are using the Client ID and Secret for the Web Application from the Partner Portal. Client IDs are 32 characters and Client Secrets are 64 characters.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. Why is the user not getting prompted to enter their credentials again after having previously logged out?
                    </div>
                    <div className={"answer"}>
                        A. This is because you also need to clear the cookie which can be done by redirecting the user to <code>https://auth.carfax.com/v2/logout</code>.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q.  Why am I getting an error page with the message "Uh oh. Something went wrong!"?
                    </div>
                    <div className={"answer"}>
                        A. You can find out what the error is by looking at the error_description query
                        parameter in the error page URL. Also make sure that the query parameter values for the authentication endpoint are URL encoded.
                        <br/>
                        <img alt={"uhoh"}
                              src={imgUhOh}
                         />
                        <br/>
                        These are some of the most common error scenarios:
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>Error Description</th>
                                    <th>Solution</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>The client was not found</td>
                                    <td>Make sure you are using the correct Client Id.</td>
                                </tr>
                                <tr>
                                    <td>Callback URL mismatch</td>
                                    <td>You need to add redirect_uri to the list of Callbacks for that Application.</td>
                                </tr>
                                <tr>
                                    <td>You may have pressed the back button, refreshed during login, opened too many login dialogs, or there is an issue with cookies.</td>
                                    <td>Try to log in again after some time.</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. Why am I getting an "Uh oh. Something went wrong!" error after saving my
                        Client ID when I try using the CARFAX Connect Explorer?
                    </div>
                    <div className={"answer"}>
                        A. Ensure you're using a Single Page Application Client ID for Connect Explorer. You should use the one we
                        created for you by default called Carfax GraphiQL Test Client.
                    </div>
                    <br/>
                    <div className={"question"}>
                        Q. Why am I getting an encryption related error when attempting to send a request to CARFAX
                        Connect?
                    </div>
                    <div className={"answer"}>
                        A. CARFAX Connect uses TLSv1.1+ encryption, so make sure your application's HTTP
                        framework supports at least TLSv1.1.
                    </div>
                    <br/>

                    <div className={"question"}>
                        Q. What is the difference between an Access Token and a Refresh Token?
                    </div>
                    <div className={"answer"}>
                        A. The Access Token is a short-lived JWT valid for 24 hours that will be used to send
                        requests to CARFAX Connect. The Refresh Token is valid for 30 days but may only be used one time to refresh
                        the Access Token. This ensures that the User is not required to re-enter their credentials each time
                        a report is run.
                    </div>
                    <br/>
                    <div className={"question"}>
                        Q. I've been using a legacy CARFAX socket or CARFAX Partner Web Service and featuring data points like Total Loss, Structural
                        Damage, Airbag Deployment, Odometer Rollback, Accident/Damage, Manufacturer Recall and Buyback
                        Guarantee. Now it appears I can only show 4 or 2 Pillar data. Is this correct and if so why?
                    </div>
                    <div className={"answer"}>
                        A. As we roll out this new API, we are taking the opportunity to create a more consistently branded
                        experience for dealers that leverages our CARFAX “4 Pillars” data points which consumers see
                        regularly on our Used Car Listings site and other key partner sites. You can get more details about
                        the 4 Pillars data <Link to={Constants.DEALER_PAGE_URL+"#four-pillars"}>here</Link>. While today
                        dealers in your application may be seeing the parsed data like total loss, damage, airbag
                        deployment, etc. this set of accident/damage data will be rolled up to our first Accident pillar via
                        Connect. It will show the “worse case” record. So a car with a branded title and a minor accident
                        will show the branded title. The Buyback Guarantee while still available on our reports is not a
                        focus going forward so it is no longer provided via Connect. Finally, a Recall flag is not yet
                        available but is slated to be added later this year for our Enhanced partners.<br/>
                        Note that Basic partners are provisioned with the ability to show the Accident
                        and # of Owners pillars. If you are interested in uncovering the other two pillars or recalls in the
                        future please reach out to your Business Development account manager to review your contract level.
                    </div>
                </section>
                <hr/>
                <section>
                    <h3 id={"consumer"}>Consumer</h3>
                    <div className={"question"}>
                        Q. Why am I getting an Unauthorized error when attempting to run an icr query on CARFAX Connect?
                    </div>
                    <div className={"answer"}>
                        A. Ensure you use the <Link to={Constants.AUTHENTICATION_PAGE_URL+"#client-credentials-flow"}>Client Credentials Flow</Link>. Also ensure you're using the correct grant_type - <code>client_credentials</code>. Verify that you are using the Client ID and Secret for the Client Credentials app from the Partner Portal. Client IDs are 32 characters and Client Secrets are 64 characters.
                    </div>
                    <br/>
                    <div className={"question"}>
                        Q. Why am I getting an encryption related error when attempting to send a request to CARFAX Connect?
                    </div>
                    <div className={"answer"}>
                        A. CARFAX Connect uses TLSv1.1+ encryption, so make sure your application's HTTP framework supports at least TLSv1.1.
                    </div>
                    <br/>
                </section>
                <hr/>
            </div>
        )
    }

}


