import {Auth0Client, RedirectLoginResult} from "@auth0/auth0-spa-js";
import Constants from "../Constants";
import Auth0UserInfo from "../model/Auth0UserInfo";


export default class GraphqlAuthentication{

    private readonly level: string = process.env.REACT_APP_ENVIRONMENT ?? "local"
    private auth0Client: Auth0Client

    public constructor(clientId : string) {
        this.auth0Client = new Auth0Client({
            audience: 'https://connect.carfax.com',
            client_id: clientId,
            scope: 'openid profile',
            domain: Constants.AUTH_DOMAIN.get(this.level ?? 'local')!,
            response_type: 'token id_token',
            redirect_uri: `${Constants.AUTH_CLIENT_DOMAIN.get(this.level)}/explorer`,
        })
    }

    public redirectToUniversalLogin(): Promise<void> {
        return this.auth0Client.loginWithRedirect({
            mode: 'partner'
        })
    }

    public handleAuthentication(): Promise<RedirectLoginResult> {
        return this.auth0Client.handleRedirectCallback()
    }

    public redirectToSignOut(): string {
        return `http://${Constants.AUTH_DOMAIN.get(this.level)}/v2/logout`;
    }

    public getAccessToken(): Promise<string> {
        return this.auth0Client.getTokenSilently()
    }

    public async getUserData(): Promise<Auth0UserInfo> {
        let user = await this.auth0Client.getUser()
        console.log(user)
        return new Auth0UserInfo(
            user!.name!,
            user!.updated_at!,
            user!.email === undefined ? "" : user!.email.toString(),
            user!['https://carfax.com/partner'].partnerType
        )
    }
}
