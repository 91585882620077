import React from "react";
import "./Overview.scss";
import ContentTemplate from "../ContentTemplate/ContentTemplate";
import Constants from "../Constants";
import {Link} from "react-router-dom";

interface Props {
}

interface State {
}

export default class Overview extends React.Component<Props, State> {

    private sideNavigationLinks: Map<string, string> = new Map([
        ["#introducing-carfax-connect", "Introducing CARFAX Connect"],
        ["#getting-started", "Getting Started"],
        ["#partner-types-and-schemas", "Partner Types and Schemas"],
        ["#connect-explorer", "Connect Explorer"],
        ["#support", "Support"],
        ["#partner-with-carfax", "Partner with CARFAX"],
    ]);

    private navLinksMap: Map<string, Map<string, string>> = new Map ([
        ["Overview", this.sideNavigationLinks]
    ]);

    public render() {
        return (
            <div className={"overview"}>
                <ContentTemplate
                    pageTitle="Overview"
                    navlinksMap={this.navLinksMap}
                    content={this.getContentHtml()}
                    showReleaseNotes={true}
                />
            </div>
        );
    }

    private getContentHtml(): JSX.Element {
        return (
            <div className="content-container">
                <h1 id="overview">Overview</h1>
                <section>
                    <h3 id="introducing-carfax-connect">Introducing CARFAX Connect</h3>
                    <p>
                        CARFAX Connect is a GraphQL API that enables authorized partners to integrate CARFAX products
                        and data in to their applications, platforms and websites. This highly secure and configurable
                        API provides a wide and growing range of features such as the provision of Instant CARFAX
                        Reports to consumers on dealer websites to enabling dealers to run CARFAX Reports in inventory
                        management tools to managing ratings/reviews. CARFAX Connect streamlines the delivery of these
                        valuable CARFAX features and data to meet the needs of your customer base.
                    </p>
                    <p>
                        GraphQL is a simple, standardized language for writing queries, allowing partner developers to
                        request only the specific data they need. Queries are written against a schema, which defines
                        the types and fields available in the query.
                    </p>
                    <p>
                        To learn GraphQL, we recommend these pages on&nbsp;
                        <a href="https://graphql.org/" target="_blank" rel="noopener noreferrer">GraphQL.org</a>:
                    </p>
                    <ul>
                        <li>
                            <a href="https://graphql.org/learn/queries/" target="_blank" rel="noopener noreferrer">
                                Queries and Mutations
                            </a> &mdash; Essential query syntax.
                        </li>
                        <li>
                            <a href="https://graphql.org/learn/schema/" target="_blank" rel="noopener noreferrer">
                                Schemas and Types
                            </a> &mdash; Not required for API clients, but may be helpful for
                            understanding the Connect schema.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id="getting-started">Getting Started</h3>
                    <p>
                        For details on how to set up the Connect application, authenticate, and send a request please
                        visit the respective Getting Started page noted below.
                    </p>
                    <h4>For Consumer Partners</h4>
                    <p>
                        If you are a Consumer facing partner where you may feature Instant CARFAX Reports on dealer
                        websites for example, please refer to&nbsp;
                        <Link to={Constants.GETTING_STARTED_CONSUMER_URL}>Getting Started - Consumer Partner</Link>.
                    </p>
                    <h4>For Dealer Partners</h4>
                    <p>
                        If you are a Dealer facing partner hosting an inventory management or CRM application
                        where dealers may run CARFAX Reports for example, please refer to&nbsp;
                        <Link to={Constants.GETTING_STARTED_DEALER_URL}>Getting Started - Dealer Partner</Link>.
                    </p>
                    <p>
                        <h4>For Auction Partners</h4>
                        If you are a Dealer facing auction partner where dealers may run CARFAX Reports, please refer to&nbsp;
                        <Link to={Constants.GETTING_STARTED_AUCTION_PARTNER}>Getting Started - Auction Partner</Link>.
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id="partner-types-and-schemas">Partner Types and Schemas</h3>
                    <p>
                        CARFAX Connect serves multiple partner types. Each partner can only
                        access a subset of the schema according to the partner type and
                        permissions. Rather than providing separate documentation for each
                        permutation of permissions, we provide high-level product
                        documentation for each partner type along with an interactive schema
                        browser which will show only the fields and types for which you are
                        authorized.
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id="connect-explorer">Connect Explorer</h3>
                    <p>
                        <a href={Constants.CARFAX_CONNECT_URL_MAP.get(Constants.LEVEL)} target="_blank"
                           rel="noopener noreferrer">
                            Connect Explorer
                        </a> is an interactive schema browser and query editor. It shows only
                        fields for which you have permissions.
                    </p>
                    <p>
                        Note: Connect Explorer is to be used for testing only by dealer facing
                        partners utilizing the Auth Code flow. Please use <strong>Single Page
                        Application</strong> Client
                        ID from&nbsp;
                        <a href={Constants.PARTNER_PORTAL_URL_MAP.get(Constants.LEVEL)} target="_blank"
                           rel="noopener noreferrer">
                            Partner Portal
                        </a> as CARFAX Partner Client ID in Connect Explorer.
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id="support">Support</h3>
                    <p> Support issues related to the performance of the CARFAX service or errors should be sent to CARFAX
                        Partner Help at <a href="mailto:PartnerHelp@carfax.com"> PartnerHelp@carfax.com</a> or call
                        571-321-7413 option 1. Note that support emails are monitored during normal business hours (M-F;
                        9am to 5pm eastern). Please use the telephone number above for emergencies that occur after hours.
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id="partner-with-carfax">Partner with CARFAX</h3>
                    <p>
                        If you want to integrate CARFAX services into your software platform
                        or have a major automotive site and are interested in partnering
                        with us, contact the CARFAX Business Development team at&nbsp;
                        <a href="mailto:BusDevPartner@carfax.com">BusDevPartner@carfax.com</a>.
                    </p>
                </section>
            </div>
        );
    }
}
