import React, {Component} from 'react';
import './GettingStartedConsumer.scss';
import ContentTemplate from "../ContentTemplate/ContentTemplate";
import {HashLink as Link} from "react-router-hash-link";
import Constants from "../Constants";
import CodeSnippetTemplate from "../CodeSnippetTemplate/CodeSnippetTemplate";
import CodeSnippet from "../../model/CodeSnippet";
import {CodeSnippetLanguages} from "../CodeSnippetTemplate/CodeSnippetLanguages";
import PartnerPortalApplications from "../../assets/images/partner-portal-applications.png";
import CollapsibleContent from "../CollapsibleContent/CollapsibleContent";

interface State {

}

interface Props {

}


export default class GettingStartedConsumer extends Component<Props, State> {

    private sideNavigationLinks: Map<string, string> = new Map([
        ["#schema", "Schema"],
        ["#prerequisites", "Prerequisites"],
        ["#step-1-access-token", "Step 1 - Access Token"],
        ["#step-2-graphql-query", "Step 2 - GraphQL Query"],
        ["#step-3-send-request", "Step 3 - Send Request"]
    ]);

    private navLinksMap: Map<string, Map<string, string>> = new Map ([
        ["Getting Started", this.sideNavigationLinks]
    ]);

    public render() {
        return (
            <div className={"getting-started-consumer"}>
                <ContentTemplate
                    pageTitle="Getting Started"
                    navlinksMap={this.navLinksMap}
                    content={this.getContentHtml()}
                />
            </div>
        )
    }

    private getContentHtml(): JSX.Element {
        return (
            <div className="content-container">
                <h1 id={"schema"}>Getting Started - Consumer Partner</h1>
                <section>
                    <p>
                        The Consumer Partner path of CARFAX Connect is used by Consumer facing Partners to
                        retrieve the Instant CARFAX Report (ICR) link to display on their site. This guide will
                        outline the steps to make a basic GraphQL query to CARFAX Connect to retrieve an ICR link.
                        Note that as a Consumer partner you may too be provisioned with the means to query and post
                        CARFAX 4 Pillar data and Snapshot. Details on these features and how to query for
                        them may be found on the <Link to={Constants.CONSUMER_PAGE_URL}>Consumer Partner</Link> page.
                    </p>
                    <p>
                        CARFAX Connect is a GraphQL API which is based on the idea of "you get what you request". If
                        you're not familiar with to GraphQL, please refer to&nbsp;
                        <a href={"https://graphql.org/"} target={"_blank"} rel={"noopener noreferrer"}>GraphQL.org</a> for more information.
                    </p>
                </section>
                <hr/>
                <section>
                    <h3>API Schema</h3>
                    <p>
                        For the purposes of this guide, the following Schema will be used:
                        <br/>
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(
                                CodeSnippetLanguages.GRAPHQL,
                                "GraphQL",
                                `extend type Query {
    icr(icrInput: IcrInput): IcrResult
}
 
# Parameters required to locate an Instant CARFAX Report
input IcrInput {
    # VIN of the vehicle
    vin: Vin!
 
    # Dealer ID assigned by the partner that originated the Inventory
    dealerId: String!
}
 
# Interface representing the result of an Instant CARFAX Report query, whether the report is available or not.
# If the report is available, the concrete type will be IcrAvailable; otherwise, it will be IcrNotAvailable.
interface IcrResult {
    carfaxLink: CarfaxLink!
    dealerId: String!
}
 
# Link to the CARFAX report or purchase page (record check) if report not available
interface CarfaxLink {
    # CARFAX web page
    url: Url!
 
    # Image that can be displayed as the link
    iconUrl: Url!
}
 
# Instant CARFAX Report link and related data elements available only when the ICR is available
type IcrAvailable implements IcrResult {
    # Link to the CARFAX report
    carfaxLink: IcrLink!
 
    # Snapshot is a summary of the most relevant vehicle history data represented as icon/text pairs.
    snapshot: Snapshot                         
     
    # Dealer ID assigned by the partner that originated the Inventory
    dealerId: String!
}
 
# Data that is available when the Instant CARFAX Report is not available
type IcrNotAvailable implements IcrResult {
    # Link for consumer to purchase a CARFAX report (Record Check page)
    carfaxLink: RecordCheckLink!
 
    # Dealer ID assigned by the partner that originated the Inventory
    dealerId: String!
}
 
# Link directly to CARFAX report
type IcrLink implements CarfaxLink {
    # CARFAX report
    url: Url!
 
    # Image that can be displayed as the link
    iconUrl: Url!
 
    # Time when the link is no longer valid
    expiresAt: Timestamp!
}
 
# Link for consumer to purchase a CARFAX Report (Record Check page)
type RecordCheckLink implements CarfaxLink {
    # Page where consumer can purchase CARFAX Report
    url: Url!
 
    # Image that can be displayed as the link
    iconUrl: Url!
}
 
# Vehicle Identification Number; 17 uppercase alphanumeric characters
scalar Vin
 
# An absolute URL, with encoding applied as needed
scalar Url
 
# RFC 3339 date & time (an ISO 8601 profile) such as '2019-02-01T13:22:33Z'
scalar RfcTimestamp
 
# Integer in the JavaScript safe range -(2^53 - 1) to 2^53 - 1
scalar IntJs
 
# Seconds represented as an IntJs scalar
scalar Seconds
 
# Milliseconds represented as an IntJs scalar
scalar Milliseconds
 
# Instant in time (not local)
type Timestamp {
    # UNIX timestamp; seconds elapsed since the UNIX epoch, 1970-01-01T00:00:00Z, neglecting leap seconds
    unix: Seconds!
 
    # Milliseconds elapsed since the UNIX epoch, 1970-01-01T00:00:00Z, neglecting leap seconds
    unixMillis: Milliseconds!
 
    # Date-time string in RFC 3339/ISO 8601 format, such as '2019-02-01T13:22:33Z'
    rfc: RfcTimestamp!
}`
                            )
                        ]}/>
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id={"prerequisites"}>Prerequisites</h3>
                    <p>
                        Before you can authenticate and start running queries on CARFAX Connect, you must have
                        a <strong>Client
                        Credentials</strong> Application set up in the Partner Portal. You can choose to use the one we
                        provided
                        (App Type - Client Credentials), or use one that you create.
                    </p>
                    <p className={"text-center"}>
                        <img
                            src={PartnerPortalApplications}
                            width="647px"
                            alt="Partner Portal: Applications Screen"
                        />
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id={"step-1-access-token"}>Step 1 - Get an Access Token</h3>
                    <p>
                        CARFAX Connect follows the OAuth 2.0 authentication process.
                        You'll be using the Client Credentials flow to get an Access Token.
                        This Access Token will be used to interact with CARFAX Connect.
                        An access token once obtained is valid for 24 hours and should
                        be reused until it expires. The expiration is identified via &nbsp;
                        <code>expires_in</code> which is in seconds. The Client ID and Secret
                        would be from the Client Credentials application you created
                        in the prerequisites. Below is a cURL version of the request:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.MARKUP, 'cURL',
                                `curl -XPOST \\
    -H "Content-type: application/json" \\
    -d '{"client_id":"YOUR_CLIENT_ID","client_secret":"YOUR_CLIENT_SECRET","audience":"https://connect.carfax.com","grant_type":"client_credentials"}' 'https://auth.carfax.com/oauth/token'
`)
                        ]}/>
                    </p>
                    <p>Response:
                    <CodeSnippetTemplate codeSnippets={[
                        new CodeSnippet(CodeSnippetLanguages.JSON, 'JSON',
                            JSON.stringify(
                                {
                                    "access_token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9....",
                                    "expires_in": 86400,
                                    "token_type": "Bearer"
                                }, null, 4
                            ))
                    ]}/>
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id={"step-2-graphql-query"}>Step 2 - Prepare the GraphQL Query</h3>
                    <p>
                        For the purposes of this guide, we will use the following GraphQL query. Specify a VIN and the
                        dealerId from your system as the input parameters. Since the type `icr` query can return either
                        IcrAvailable or IcrNotAvailable concrete type depending on if the report exists or not
                        respectively, we need to handle both cases:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(
                                CodeSnippetLanguages.GRAPHQL,
                                "GraphQL",
                                `query {
  icr(icrInput: {vin: "2GNAXYEX7K6158647", dealerId: "1234QWER"}) {
    ... on IcrAvailable {
      carfaxLink {
        url
        expiresAt {
          unix
        }
      }
    }
    ... on IcrNotAvailable {
      carfaxLink {
        url
      }
    }
  }
}`
                            )
                        ]}/>
                    </p>
                    <p>
                        <strong>Note:</strong> If you are a partner who supports only the VIN to run an ICR, then
                        you need to provide only the VIN and none of the other parameters to get an ICR. Your query will
                        instead look like this:
                        <CollapsibleContent content={
                            <CodeSnippetTemplate codeSnippets={[
                                new CodeSnippet(
                                    CodeSnippetLanguages.GRAPHQL,
                                    "GraphQL",
`query {
  icr(icrInput: {vin: "2GNAXYEX7K6158647"}) {
    ... on IcrAvailable {
      carfaxLink {
        url
        expiresAt {
          unix
        }
      }
    }
    ... on IcrNotAvailable {
      carfaxLink {
        url
      }
    }
  }
}`
                                )
                            ]}/>}
                        />
                    </p>
                    <p>
                        Convert your GraphQL to a JSON object. You can also use commas(,) or spaces to separate the
                        different types in the query instead of the newline character(\n). GraphQL is very flexible when
                        it comes to how you want to format the query if readability is important:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON, 'JSON',
                                JSON.stringify(
                                    {
                                        "query": "query {\n  icr(icrInput: {vin: \"2GNAXYEX7K6158647\", dealerId: \"1234QWER\"}) {\n    ... on IcrAvailable {\n      carfaxLink {\n        url\n        expiresAt {\n                unix\n        }\n      }\n    }\n    ... on IcrNotAvailable {\n      carfaxLink {\n        url\n      }\n    }\n  }\n}",
                                        "variables": null
                                    }, null, 4
                                ))
                        ]}/>
                    </p>
                    <p>
                        For more information on sending GraphQL queries, and what type of queries CARFAX Connect
                        supports, please refer to&nbsp;
                        <Link to={Constants.SEND_QUERIES_PAGE_URL}>Sending Queries page</Link>.
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id={"step-3-send-request"}>Step 3 - Sending a request to CARFAX Connect</h3>
                    <p>
                        Now you are ready to send a request to CARFAX Connect. Below is the cURL version of the request:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.MARKUP, 'cURL',
                                `curl -XPOST
    -H "Content-type: application/json"
    -H "Authorization: Bearer <access token from Step 1>"
    -d '{"query":"query {\\n  icr(icrInput: {vin: \\"2GNAXYEX7K6158647\\", dealerId: \\"1234QWER\\"}) {\\n    ... on IcrAvailable {\\n      carfaxLink {\\n        url\\n        expiresAt {\\n          unix\\n        }\\n      }\\n    }\\n    ... on IcrNotAvailable {\\n      carfaxLink {\\n        url\\n      }\\n    }\\n  }\\n}","variables":null}' 'https://connect.carfax.com/v1/graphql'
`)
                        ]}/>
                    </p>
                    <p>
                        If the ICR is available, you will receive the following response. The URL property contains the
                        link to the report:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON, 'JSON',
                                JSON.stringify(
                                    {
                                        "data": {
                                            "icr": {
                                                "carfaxLink": {
                                                    "url": "https://www.carfax.com/VehicleHistory/ar20/K9rr0LBSzBZAF2",
                                                    "expiresAt": {
                                                        "unix": 1581107915
                                                    }
                                                }
                                            }
                                        }
                                    }, null, 4
                                ))
                        ]}/>
                    </p>
                    <p>
                        If the ICR is not available, you will receive the following response. The URL property contains
                        the link to the Record Check:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON, 'JSON',
                                JSON.stringify(

                                    {
                                        "data": {
                                            "icr": {
                                                "carfaxLink": {
                                                    "url": "https://www.carfax.com/cfm/check_order.cfm?partner=ABC_1&vin=2GNAXYEX7K6158647"
                                                }
                                            }
                                        }
                                    }, null, 4
                                ))
                        ]}/>
                    </p>
                    <p>
                        For more information on the Consumer path, please refer to this&nbsp;
                        <Link to={Constants.CONSUMER_PAGE_URL}>link</Link>.
                    </p>
                </section>
            </div>
        )
    }
}
