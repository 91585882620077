import React, {Component, createRef} from "react";
import "./ContentTemplate.scss";
import {Col, ListGroup, Row} from "react-bootstrap";
import {HashLink as Link} from "react-router-hash-link";
import Constants from "../Constants";

interface Props {
    pageTitle: string;
    navlinksMap: Map<string, Map<string, string>>;
    content: JSX.Element;
    showReleaseNotes?: boolean;
}

interface State {
    sections: any[];
    active: number;
}

export default class ContentTemplate extends Component<Props, State> {
    private readonly bodyRef: any = createRef<HTMLDivElement>();

    constructor(props: Props) {
        super(props);
        this.state = {
            sections: [],
            active: 0,
        };
    }

    public componentDidMount() {
        // set the tab title
        if (this.props.pageTitle !== "Overview") {
            window.document.title = this.props.pageTitle + " - CARFAX Connect";
        } else {
            window.document.title = "CARFAX Connect"; // special case for the Overview/Home Page
        }

        window.scrollTo(0, 0);
        window.addEventListener("scroll", this.handleScroll.bind(this));

        let sections: any[] = [];
        const divNode = this.bodyRef.current;
        if (divNode) {
            sections = divNode.getElementsByTagName("h3");
            this.setState({sections: sections});
        }
    }

    private handleScroll(e: any) {
        const scrollPos = window.scrollY;
        const sections = this.state.sections;
        const lastIndex = sections.length - 1;

        for (let i = 0; i < lastIndex; i++) {
            if ((scrollPos > sections[i].offsetTop + 20) && (scrollPos < sections[i + 1].offsetTop + 20)) {
                this.setState({active: i});
            }
        }

        if (scrollPos > sections[lastIndex].offsetTop + 20) {
            this.setState({active: lastIndex});
        }
    }

    public render() {
        let navItems: JSX.Element[] = [];
        let idx = 0;

        this.props.navlinksMap.forEach((links, title) => {
            let navLinks: JSX.Element[] = []
            links.forEach((navTitle, navId) => {
                let navLink = (
                    <ListGroup.Item as={"li"} key={navTitle} active={this.state.active === idx}>
                        <Link
                            to={navId}
                            smooth={true}
                            className={"nav-link padding-for-sidenav"}
                        >
                            {navTitle}
                        </Link>
                    </ListGroup.Item>
                )
                navLinks.push(navLink);
                idx++;
            })

            let navItem = (
                <>
                    <h5>{title}</h5>
                    <ListGroup as={"ul"} className={"list-group-flush"}>
                        {navLinks}
                    </ListGroup>
                </>
            )

            navItems.push(navItem)
        });

        return (
            <div className={"content-template-container"}>
                <Row>
                    <Col md={"3"}>
                        <div className="sidebar" id="spySidebar">
                            {navItems}
                            {
                                this.props.showReleaseNotes &&
                                <>
                                    <hr/>
                                    <div className="recent-updates-container">
                                        <h5>Recent Updates</h5>
                                        <p>
                                            Updates are available! Check them out by visiting&nbsp;
                                            <Link to={Constants.RECENT_UPDATES_URL}>Recent Updates</Link>
                                        </p>
                                    </div>
                                </>
                            }
                        </div>
                    </Col>
                    <Col
                        md={"9"}
                        className={"content-documentation-container"}
                        ref={this.bodyRef}
                    >
                        <div style={{textAlign:"center"}}>
                            <p><span style={{color:"red"}}>IMPORTANT</span> - Please do not deploy CARFAX Connect to production until a demo has been conducted with your Account Manager.</p>
                        </div>
                        {this.props.content}
                    </Col>
                </Row>
            </div>
        );
    }
}
