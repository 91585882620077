import {CodeSnippetLanguages} from "../components/CodeSnippetTemplate/CodeSnippetLanguages";

export default class CodeSnippet {

    private code: string
    private languageName: string
    private language: CodeSnippetLanguages

    public constructor(language: CodeSnippetLanguages, languageName: string, code: string) {
        this.language = language
        this.languageName = languageName
        this.code = code
    }

    public setCode(code: string): void {
        this.code = code
    }

    public getCode(): string {
        return this.code;
    }

    public setLanguageName(languageName: string): void {
        this.languageName = languageName
    }

    public getLanguageName(): string {
        return this.languageName;
    }

    public setLanguage(language: CodeSnippetLanguages): void {
        this.language = language
    }

    public getLanguage(): CodeSnippetLanguages {
        return this.language;
    }
}