import React, {Component} from "react";
import "./SendingQueries.scss";
import ContentTemplate from "../ContentTemplate/ContentTemplate";
import {ListGroup} from "react-bootstrap";
import CodeSnippetTemplate from "../CodeSnippetTemplate/CodeSnippetTemplate";
import {CodeSnippetLanguages} from "../CodeSnippetTemplate/CodeSnippetLanguages";
import CodeSnippet from "../../model/CodeSnippet";

export default class SendingQueries extends Component<any, any> {
    private sideNavigationLinks: Map<string, string> = new Map([
        ["#http-request", "HTTP Request"],
        ["#variables", "Variables"],
        ["#example-raw-http-request", "Example HTTP Request"],
        ["#batch-requests", "Batch Requests"],
        ["#fragments", "Fragments"]
    ]);

    private navLinksMap: Map<string, Map<string, string>> = new Map ([
        ["Sending Queries", this.sideNavigationLinks]
    ]);

    public render() {
        return (
            <div className={"sending-queries"}>
                <ContentTemplate
                    pageTitle="Sending Queries"
                    navlinksMap={this.navLinksMap}
                    content={this.getContentHtml()}
                />
            </div>
        );
    }

    private getContentHtml(): JSX.Element {
        return (
            <div className={"content-container"}>
                <h1>Sending Queries</h1>
                <section>
                    <h3 id="http-request">HTTP Request</h3>
                    <p>Send a query by making an HTTP request.</p>
                    <ListGroup as={"ul"} className={"list-group-flush outer"}>
                        <ListGroup.Item>
                            All requests <em>must</em> use secure HTTPS
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Use the <code>POST</code> method for both queries and mutations
                        </ListGroup.Item>
                        <ListGroup.Item>
                            URL: <code>https://connect.carfax.com/v1/graphql</code>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Headers:
                            <ListGroup as={"ul"} className={"list-group-flush inner"}>
                                <ListGroup.Item>
                                    <code>Content-Type: application/json</code>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <code>Authorization: Bearer &lt;token&gt;</code>
                                </ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            Entity body: JSON object with the following properties:
                            <ListGroup as={"ul"} className={"list-group-flush inner"}>
                                <ListGroup.Item>
                                    <code>query</code>: a string, which is the GraphQL query itself
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <code>variables</code> (optional): input variables for the query
                                </ListGroup.Item>
                            </ListGroup>
                        </ListGroup.Item>
                    </ListGroup>
                </section>
                <hr/>
                <section>
                    <h3 id="variables">Variables</h3>
                    <p>
                        When a field needs input arguments, never interpolate the values
                        directly into the query string. This approach would make your query
                        vulnerable to injection attacks.
                    </p>
                    <p>For example, suppose you build a query like this:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JAVA,
                                "Java", `String query = "query {vehicle(vin: \\"" + vin + "\\"){model}}";`
                            )
                        ]}/>
                    </p>
                    <p>
                        A malicious user could attempt to get the following "VIN" into the
                        system: <code>123"){"}"}</code>. At this point the user would have
                        escaped the intended string literal context and could actually modify
                        the query structurally. Additionally, this query cannot be cached
                        because the VIN will change often.
                    </p>
                    <p>Both problems are solved using GraphQL variables:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JAVA,
                                "Java", `String query = "query ($vin: String) {vehicle(vin: $vin){model}}";`
                            )
                        ]}/>
                    </p>
                    <p>
                        With the above query, the request JSON would include a
                        <code>variables</code> property. Be sure to use a JSON serializer
                        rather than attempting to build the JSON string manually so that
                        values will be properly escaped for the JSON context.
                    </p>
                    <p>
                        For more on GraphQL variables, see
                        <a
                            href="https://graphql.org/learn/queries/#variables"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {" "}
                            GraphQL.org: Variables
                        </a>
                        .
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id="example-raw-http-request">Example HTTP Request</h3>
                    <p>GraphQL query:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.GRAPHQL,
                                "GraphQL",
                                `query ($icrInput: IcrInput!) {
    icr(icrInput : $icrInput) {
        carfaxLink {
            url
        }
    }
}`
                            )
                        ]}/>
                    </p>
                    <p>Full, raw HTTP request for above query:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "cURL",
                                `POST /v1/graphql HTTP/1.1
Host: connect.carfax.com
Content-Type: application/json
Authorization: Bearer YourAccessToken
Content-Length: 228

{
  "query":"query($icrInput: IcrInput!){icr(icrInput: $icrInput){carfaxLink{url}}}",
  "variables":{
    "icrInput":{
      "vin":"2ABCD4JCXDBE32123",
      "originatingPartner":"ORGLMXJ001",
      "dealerId":"1504"
    }
  }
}`
                            )
                        ]}/>
                    </p>
                    <p>
                        (Note: For readability, the line breaks were removed in the query in
                        the raw request. If the query string included line breaks, the JSON
                        serializer would escape them as <code>\n</code> since line breaks are
                        not allowed inside JSON strings.)
                    </p>
                    <p>Sample response JSON:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON",
                                JSON.stringify({
                                    "data": {
                                        "icr": {
                                            "carfaxLink": {
                                                "url": "https://www.carfax.com/VehicleHistory/ar20/zZDcktxGO1YXoXbfkhdZ7"
                                            }
                                        }
                                    }
                                }, null, 4)
                            )
                        ]}/>
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id="batch-requests">Batch Requests</h3>
                    <p>
                        Batching is the process of taking a group of requests, combining them into one, and making a
                        single request with the same data that all of the other queries would have made. They can be
                        used mostly to improve performance.  For additional performance improvements multi-threaded
                        batch requests may also be used.
                        <br/>
                        <br/>
                        For all products except Stoplight you can send up to 100 queries in a single HTTP batch request.
                        25 queries in a single batch request is the maximum for Stoplight to ensure optimal performance.
                        Simply send a JSON array of objects, where each object has <code>query</code> and (optional)
                        <code>variables</code> properties. The response will be a JSON array of result objects in the
                        same order as the queries.
                        <br/>
                        <br/>
                        Batch queries can be written in 2 ways – using a JSON array of GraphQL
                        query objects or using&nbsp;
                        <a href="https://graphql.org/learn/queries/#aliases" target="_blank" rel="noopener noreferrer">
                            GraphQL Aliases
                        </a>. Both methods are supported in Connect.
                    </p>
                </section>
                <hr/>
                <section>
                    <h3>JSON Array of GraphQL Objects</h3>
                    <p>
                        This method of utilizing the Batch Requests feature is essentially an array of individual
                        queries that are sent as a single request to the server. At the server
                        side, the objects in the request are processed
                        separately.
                    </p>
                    <p>Example request body:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(
                                CodeSnippetLanguages.JSON,
                                "JSON",
                                JSON.stringify([
                                    {
                                        "query": "query {dealerReport(vin:\"VIN1\") { carfaxLink { url } snapshotKey }}"
                                    },
                                    {
                                        "query": "query {dealerReport(vin:\"VIN2\") { carfaxLink { url } snapshotKey }}"
                                    }
                                ], null, 4)
                            )
                        ]}/>
                    </p>
                    <p>Example response body:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON",
                                JSON.stringify([
                                    {
                                        "data": {
                                            "dealerReport": {
                                                "carfaxLink": {
                                                    "url": "SomeURL"
                                                },
                                                "snapshotKey": "SomeKey"
                                            }
                                        }
                                    },
                                    {
                                        "data": {
                                            "dealerReport": {
                                                "carfaxLink": {
                                                    "url": "SomeURL"
                                                },
                                                "snapshotKey": "SomeKey"
                                            }
                                        }
                                    }
                                ], null, 4)
                            )
                        ]}/>
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id={"graphql-aliases"}>GraphQL Aliases</h3>
                    <p>
                        The <a href="https://graphql.org/learn/queries/#aliases" target="_blank"
                               rel="noopener noreferrer">
                        GraphQL Aliases
                    </a> feature is the other way of requesting for multiple queries in one request. In this method,
                        the objects that you are querying get treated as one single GraphQL operation. Use this option
                        when you
                        need a convenient way of mapping your query results to your query requests.
                    </p>
                    <p>Example of a GraphQL Request body using aliases for <code>DealerReport</code>:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.GRAPHQL,
                                "GraphQL",
                                `query {
    vin1: dealerReport(vin:"VIN1") {
        carfaxLink { 
            url 
        } 
        snapshotKey 
    } 
    
    vin2: dealerReport(vin:"VIN2") {
        carfaxLink { 
            url 
        } 
        snapshotKey 
    }
    
    vin3: dealerReport(vin:"VIN3") {
        carfaxLink { 
            url 
        } 
        snapshotKey 
    }
}`
                            )
                        ]}/>
                    </p>
                    <p>Example response body:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON",
                                JSON.stringify({
                                    "data": {
                                        "vin1": {
                                            "carfaxLink": {
                                                "url": "SomeURL1"
                                            },
                                            "snapshotKey": "SomeKey1"
                                        },
                                        "vin2": {
                                            "carfaxLink": {
                                                "url": "SomeURL2"
                                            },
                                            "snapshotKey": "SomeKey2"
                                        },
                                        "vin3": {
                                            "carfaxLink": {
                                                "url": "SomeURL3"
                                            },
                                            "snapshotKey": "SomeKey3"
                                        }
                                    }
                                }, null, 4)
                            )
                        ]}/>
                    </p>
                    <p>Example of a GraphQL Request body using aliases for <code>icr</code>:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.GRAPHQL,
                                "GraphQL",
                                `query($icrInput: IcrInput!) {
    vin1: icr(icrInput: $icrInput) {
        __typename
        carfaxLink {
            url
            iconUrl
            ... on IcrLink {
                expiresAt {rfc}
            }
        }
    }
    
    vin2: icr(icrInput: $icrInput) {
        __typename
        carfaxLink {
            url
            iconUrl
            ... on IcrLink {
                expiresAt {rfc}
            }
        }
    } 
}`
                            )
                        ]}/>
                    </p>
                    <p>Example response body:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON",
                                JSON.stringify({
                                    "data": {
                                        "vin1": {
                                            "__typename": "IcrNotAvailable",
                                            "carfaxLink": {
                                                "url": "https:/carfax.com/cfm/check_order.cfm?partner=ABC_1&vin=VIN1",
                                                "iconUrl": "https:/partnerstatic.carfax.com/img/valuebadge/showme.svg"
                                            }
                                        },
                                        "vin2": {
                                            "__typename": "IcrNotAvailable",
                                            "carfaxLink": {
                                                "url": "https:/carfax.com/cfm/check_order.cfm?partner=DEF_1&vin=VIN2",
                                                "iconUrl": "https:/partnerstatic.carfax.com/img/valuebadge/showme.svg"
                                            }
                                        }
                                    }
                                }, null, 4)
                            )
                        ]}/>
                    </p>
                    <p>Query Variables:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON",
                                JSON.stringify({
                                    "icrInput": {
                                        "vin": "VIN1",
                                        "originatingPartner": "Partner1",
                                        "dealerId": "1234"
                                    }
                                }, null, 4)
                            )
                        ]}/>
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id={"fragments"}>Fragments</h3>
                    <p>
                        <a href="https://graphql.org/learn/queries/#fragments"
                           target="_blank" rel="noopener noreferrer">Fragments
                        </a> are a GraphQL feature that can be used to simplify your query.
                        It allows the reusability of part of the query and
                        keeps the request light by highlighting the main areas that are
                        different between each query and automatically handling the common
                        parts.
                    </p>
                    <p>
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.GRAPHQL,
                                "GraphQL",
                                `query {
    vin1: dealerReport(vin: "VIN1") {
        ...dealerReportRetFields
    }
    
    vin2: dealerReport(vin: "VIN2") {
        ...dealerReportRetFields
    }
}

fragment dealerReportRetFields on DealerReport {
    fourPillars {
        fourPillarsEnabled
        
        accident {
            hasAccidents
            searchableText
        }
        
        owner {
            isOneOwner
            iconText
        }
        
        useType {
            isPersonalUse
            iconText
        }
        
        serviceRecord {
            recordCount
        }
    }
}`
                            )
                        ]}/>
                    </p>
                </section>
            </div>
        );
    }
}
