export default class Constants {
    public static readonly LEVEL: string = process.env.REACT_APP_ENVIRONMENT ?? "local"
    public static readonly HOME_PAGE_URL = `${process.env.PUBLIC_URL}/`;
    public static readonly HOME_PAGE_INDEX_URL = `${process.env.PUBLIC_URL}/index.html`;
    public static readonly SEND_QUERIES_PAGE_URL = `${Constants.HOME_PAGE_URL}send-queries`;
    public static readonly AUTHENTICATION_PAGE_URL = `${Constants.HOME_PAGE_URL}authentication`;
    public static readonly ERROR_HANDLING_PAGE_URL = `${Constants.HOME_PAGE_URL}error-handling`;
    public static readonly PAGINATION_PAGE_URL = `${Constants.HOME_PAGE_URL}pagination`;
    public static readonly CONSUMER_PAGE_URL = `${Constants.HOME_PAGE_URL}consumer`;
    public static readonly DEALER_PAGE_URL = `${Constants.HOME_PAGE_URL}dealer`;
    public static readonly AUCTION_PARTNER_PAGE_URL = `${Constants.HOME_PAGE_URL}auction-partner`;
    public static readonly FSBO_PARTNER_PAGE_URL = `${Constants.HOME_PAGE_URL}for-sale-by-owner`
    public static readonly GETTING_STARTED_CONSUMER_URL = `${Constants.HOME_PAGE_URL}getting-started-consumer`;
    public static readonly GETTING_STARTED_DEALER_URL = `${Constants.HOME_PAGE_URL}getting-started-dealer`;
    public static readonly GETTING_STARTED_AUCTION_PARTNER = `${Constants.HOME_PAGE_URL}getting-started-auction-partner`;
    public static readonly REPUTATION_MANAGEMENT_PAGE_URL = `${Constants.HOME_PAGE_URL}reputation-management`;
    public static readonly OEM_PARTNER_PAGE_URL = `${Constants.HOME_PAGE_URL}oem`;
    public static readonly CONNECT_EXPLORER_URL = `${Constants.HOME_PAGE_URL}explorer`;
    public static readonly FAQ_URL = `${Constants.HOME_PAGE_URL}faq`;
    public static readonly RECENT_UPDATES_URL = `${Constants.HOME_PAGE_URL}recent-updates`;
    public static readonly SSO_IMPLEMENTATION_URL = `${Constants.HOME_PAGE_URL}sso`;
    public static readonly DEALER_REPORT_PAGE_URL = `${Constants.DEALER_PAGE_URL}#dealer-reports`;
    public static readonly STOPLIGHT_PAGE_URL = `${Constants.DEALER_PAGE_URL}#stoplight-overview`;
    public static readonly AUCTION_PARTNER_SELLER_PAID_PAGE_URL = `${Constants.AUCTION_PARTNER_PAGE_URL}#seller-paid-reports`;
    public static readonly AUCTION_ALERT_URL = `${Constants.AUCTION_PARTNER_PAGE_URL}#auction-alert`;
    public static readonly RATE_QUOTA_LIMITS_URL = `${Constants.HOME_PAGE_URL}rate-quota-limits`;
    public static readonly PARTNER_PORTAL_URL_MAP: Map<string, string> = new Map<string, string>([
        ['local', "https://partner-portal.dxd.staging.aws.carfax.io/partners"],
        ['staging', "/partners"],
        ['production', "/partners"]
    ])
    public static readonly CARFAX_CONNECT_URL_MAP: Map<string, string> = new Map<string, string>([
        ['local', "https://stagingconnect.carfax.com"],
        ['staging', "https://stagingconnect.carfax.com"],
        ['production', "https://connect.carfax.com"]
    ])

    public static readonly AUTH_DOMAIN: Map<string, string> = new Map([
        ['local', "staging-auth.carfax.com"],
        ['development', "staging-auth.carfax.com"],
        ['staging', "staging-auth.carfax.com"],
        ['production', "auth.carfax.com"]
    ])

    public static readonly AUTH_CLIENT_DOMAIN: Map<string, string> = new Map([
        ['local', `http://localhost:3000${process.env.PUBLIC_URL}`],
        ['development', `https://partner-portal.dxd.staging.aws.carfax.io${process.env.PUBLIC_URL}`],
        ['staging', `https://partner-portal.dxd.staging.aws.carfax.io${process.env.PUBLIC_URL}`],
        ['production', `https://developer.carfax.com${process.env.PUBLIC_URL}`]
    ])

}
