import React, {Component} from 'react';
import './ErrorHandling.scss';
import ContentTemplate from "../ContentTemplate/ContentTemplate";
import CodeSnippetTemplate from "../CodeSnippetTemplate/CodeSnippetTemplate";
import {CodeSnippetLanguages} from "../CodeSnippetTemplate/CodeSnippetLanguages";
import CodeSnippet from "../../model/CodeSnippet";

export default class ErrorHandling extends Component<any, any> {
    private sideNavigationLinks: Map<string, string> = new Map([
        ["#request-level-errors", "HTTP Request Errors"],
        ["#graphql-errors", "GraphQL Errors"],
        ["#error-codes", "Error Codes"]]);

    private navLinksMap: Map<string, Map<string, string>> = new Map ([
        ["Error Handling", this.sideNavigationLinks]
    ]);

    public render() {
        return (
            <div className={"error-handling"}>
                <ContentTemplate
                    pageTitle="Error Handling"
                    navlinksMap={this.navLinksMap}
                    content={this.getContentHtml()}/>
            </div>
        );
    }

    private getContentHtml(): JSX.Element {
        return (
            <div className={"content-container"}>
                <h1 id="error-handling">Error Handling</h1>
                <section>
                    <h3 id="request-level-errors">HTTP Request Errors</h3>
                    <p>If the entire request cannot be processed, the HTTP status code will
                        be in the 400 (client side) or 500 (server side) error range.
                        <table className="table table-striped table-bordered">
                            <thead>
                            <tr>
                                <th>HTTP Status</th>
                                <th>Description</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>401 Unauthorized</td>
                                <td>The <code>Authorization</code> header is missing or invalid. The token might have
                                    expired. Obtain a fresh token and ensure the header is being set correctly.
                                </td>
                            </tr>
                            <tr>
                                <td>500 Internal Server Error</td>
                                <td>A server side error prevented the request from being processed. The client can retry
                                    after some delay. Exponential backoff is recommended but not required.
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id="graphql-errors">GraphQL Errors</h3>
                    <h4 id="error-format">Error Format</h4>
                    <p>Most errors will be reported in the GraphQL response. The HTTP status
                        will be <strong>200 OK</strong> because the response was generated successfully
                        and the client can assume the response is in GraphQL standard format.</p>
                    <p>Errors are reported in the <code>errors</code> property of the response JSON.
                        Since there can be multiple errors, the <code>errors</code> value is an <em>array</em>
                        of objects. Each error object can have the following properties:</p>
                    <ul>
                        <li><code>message</code> &mdash; human-readable description of the error. <em>Do not
                            use it for programmatic error handling</em> since the message may change
                            without warning
                        </li>
                        <li><code>locations</code> &mdash; line and column number in the query string of the field
                            for which the error occurred
                        </li>
                        <li><code>path</code> &mdash; array of field names (or aliases, if used in
                            the query), starting from the query
                            root, to the field where the error occurred
                        </li>
                        <li><code>extensions</code> &mdash; information used to categorize and track the error
                            <ul>
                                <li><code>displayableErrorMessage</code> &mdash; The message that should be shown to the
                                    end user, usually dealers.</li>
                                <li><code>errorTracingId</code> &mdash; a unique ID used to track a specific error</li>
                                <li><code>requestId</code> &mdash; a unique ID used to track a specific HTTP request to
                                    Connect. Unlike errorTracingId,
                                </li>
                                <li><code>errorCode</code> &mdash; string identifying the specific
                                    type of error; it may be relied on for programmatic error handling
                                </li>
                                <li><code>vin</code> &mdash; the VIN used in an HTTP request. If the GraphQL field that
                                    generated
                                    the error does not take a VIN as input (eg. location), this property will display
                                    "N/A"
                                </li>
                                <li><code>classification</code> &mdash; the category to which the errorCode property
                                    belongs
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h4 id="single-error-example">Single Error Example</h4>
                    <p>Query:

                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.GRAPHQL,
                                "GraphQL", `query ($a: ID!) {
    location (id: $a) {
         name
    }
}`
                            )
                        ]}/>
                    </p>
                    <p>If the value of variable <code>$a</code> was invalid, the error response
                        would be:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON", `{
  "errors": [
    {
      "message": "Exception while fetching data (/location) : ID is invalid or cannot be used in this context.",
      "locations": [
        {
          "line": 2,
          "column": 5
        }
      ],
      "path": [
        "location"
      ],
      "extensions": {
        "displayableErrorMessage": "ID requested is invalid. Please check and try again. ",
        "errorTracingId": "69a520e2-cdd3-4d62-83ec-d0d0634e0ee7",
        "requestId": "-XKeqfTSC9z1zheLmrlX9uq0ANoxdp9WkUf53EVxJzl-BF-Ko_ahMw==",
        "errorCode": "INVALID_ID",
        "vin": "N/A",
        "classification": "DataFetchingException"
      }
    }
  ],
  "data": {
    "location": null
  }
}`
                            )
                        ]}/>
                    </p>
                    <h4 id="partial-data-and-multiple-errors">Partial Data and Multiple Errors</h4>
                    <p>Each field in GraphQL is evaluated independently. Therefore, it is possible
                        to get some data successfully along with one or more errors for a single query.
                        In the response <code>data</code> property, any fields that encountered errors will
                        be <code>null</code> and have a corresponding object in the <code>errors</code> array.</p>
                    <p>Sample query:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.GRAPHQL,
                                "GraphQL", `query ($icrInput: IcrInput!, $x: ID!) {
  icr(icrInput: $icrInput) {
    ... on IcrAvailable {
      carfaxLink { url }
      snapshot {
        items { text }
      }
    }
  }
  location(id: $x) {
    name
  }
}`
                            )
                        ]}/>
                    </p>
                    <p>Supposing <code>$x</code> is an invalid ID and an internal error occurs when
                        retrieving data for the <code>snapshot</code> field, you could see this response:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON", `{
  "errors": [
    {
      "message": "Exception while fetching data (/icr/snapshot) : Internal server error",
      "locations": [
        {
          "line": 5,
          "column": 7
        }
      ],
      "path": [
        "icr",
        "snapshot"
      ],
      "extensions": {
        "displayableErrorMessage": "Sorry, CARFAX is unavailable at this time. Please try again later.",
        "errorTracingId": "69a520e2-cdd3-4d62-83ec-d0d0634e0ee7",
        "requestId": "-XKeqfTSC9z1zheLmrlX9uq0ANoxdp9WkUf53EVxJzl-BF-Ko_ahMw==",
        "errorCode": "GENERIC_SERVER_ERROR",
        "vin": "1D8HB48P47F524668",
        "classification": "DataFetchingException"
      }
    },
    {
      "message": "Exception while fetching data (/location) : ID is invalid or cannot be used in this context.",
      "locations": [
        {
          "line": 10,
          "column": 3
        }
      ],
      "path": [
        "location"
      ],
      "extensions": {
        "displayableErrorMessage": "ID requested is invalid. Please check and try again.",
        "errorTracingId": "10d84d51-3ffa-400a-af73-78a63669ffd6",
        "requestId": "-XKeqfTSC9z1zheLmrlX9uq0ANoxdp9WkUf53EVxJzl-BF-Ko_ahMw==",
        "errorCode": "INVALID_ID",
        "vin": "N/A"
        "classification": "DataFetchingException"
      }
    }
  ],
  "data": {
    "icr": {
      "carfaxLink": {
        "url": "https://www.carfax.com/VehicleHistory/ar20/E6yJVKJEi-Ff1QaSMRx6B1Y"
      },
      "snapshot": null
    },
    "location": null
  }
}`
                            )
                        ]}/>
                    </p>
                    <p>In the response, <code>data.icr.snapshot</code> and <code>data.location</code>
                        are <code>null</code> and have corresponding
                        objects in the <code>errors</code> array, while <code>data.icr.carfaxLink.url</code> was
                        successfully
                        returned.</p>
                    <h4 id="batch-request-errors">Batch Request Errors</h4>
                    <p>Each query in a batch is processed independently, so errors in one
                        query do not affect others.</p>
                    <p>Example JSON batch request containing two queries:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON", `[
  {
    "query":"query($a: ID!){location(id: $a){ name }}",
    "variables":{"a":"badId"}
  },
  {
    "query":"query($a: ID!){location(id: $a){ name }}",
    "variables":{"a":"goodId"}
  }
]`
                            )
                        ]}/>
                    </p>
                    <p>This batch contains two queries, which happen to be the same.
                        The first one has an invalid ID in the input variable
                        while the second one is valid. The response will have an
                        error for the first result and the data for the second:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON", `[
  {
    "errors": [
      {
        "message": "Exception while fetching data (/location) : ID is invalid or cannot be used in this context.",
        "locations": [
          {
            "line": 1,
            "column": 16
          }
        ],
        "path": [
          "location"
        ],
        "extensions": {
          "displayableErrorMessage": "ID requested is invalid. Please check and try again.",
          "errorTracingId": "10d84d51-3ffa-400a-af73-78a63669ffd6",
          "requestId": "-XKeqfTSC9z1zheLmrlX9uq0ANoxdp9WkUf53EVxJzl-BF-Ko_ahMw==",
          "errorCode": "INVALID_ID",
          "vin": "N/A"
          "classification": "DataFetchingException"
        }
      }
    ],
    "data": {
      "location": null
    }
  },
  {
    "data": {
      "location": {
        "name": "Example Location"
      }
    }
  }
]`
                            )
                        ]}/>
                    </p>
                    <h3 id="error-codes">Error Codes</h3>
                    <p>The <code>extensions.errorCode</code> property of the error objects can be used for
                        programmatic categorization or handling logic.</p>
                    <p>Most of the error codes indicate a client-side error such as an invalid
                        ID or an unauthorized access. Clients should not retry such queries
                        because they will just fail again. However, <code>GENERIC_SERVER_ERROR</code> is
                        unique because it represents an internal error on the server side.
                        Clients may retry such requests after some delay. Exponential backoff
                        is recommended but not required.</p>
                    <p>The table below represents the array of error codes returned by Connect listed by
                        product.  The dealer/user facing error messages are intended for display once an
                        error has occurred. The message is given under "displayableErrorMessage" in the
                        GraphQL error "extensions" section of the response.</p>
                    <br/>

                    <table className="table table-striped table-bordered">
                        <thead>
                        <tr>
                            <th>Code</th>
                            <th>Partner Type</th>
                            <th>Product</th>
                            <th>Description</th>
                            <th>Displayable Error Message</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>GENERIC_SERVER_ERROR</td>
                            <td>All Partner Types</td>
                            <td>All Products</td>
                            <td>An internal error occurred on the server; the client may retry after a delay</td>
                            <td>Sorry, CARFAX is unavailable at this time. Please try again later</td>
                        </tr>
                        <tr>
                            <td>INVALID_PARTNER_ACCOUNT</td>
                            <td>All Partner Types</td>
                            <td>All Products</td>
                            <td>Please contact CARFAX Partner Help to resolve this issue.</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>INVALID_PARTNER_COMP_CODE</td>
                            <td>All Partner Types</td>
                            <td>All Products</td>
                            <td>Please contact CARFAX Partner Help to resolve this issue.</td>
                            <td>Sorry, CARFAX is unavailable at this time. Please try again later.</td>
                        </tr>
                        <tr>
                            <td>MISSING_DATA</td>
                            <td>All Partner Types</td>
                            <td>All Products</td>
                            <td>Invalid or missing data in the request.</td>
                            <td>Sorry, CARFAX is unavailable at this time. Please try again later.</td>
                        </tr>
                        <tr>
                            <td>QUOTA_EXCEEDED</td>
                            <td>All Partner Types</td>
                            <td>All Products</td>
                            <td>The Connect daily request quota has been exceeded.  Please contact your CARFAX account manager or Partner Help to resolve this issue.</td>
                            <td>Sorry, CARFAX is unavailable at this time. Please try again later.</td>
                        </tr>
                        <tr>
                            <td>RATE_LIMIT_EXCEEDED</td>
                            <td>All Partner Types</td>
                            <td>All Products</td>
                            <td>The Connect rate limit has been exceeded.  Please reduce the number of requests being made.</td>
                            <td>Sorry, CARFAX is unavailable at this time. Please try again later.</td>
                        </tr>
                        <tr>
                            <td>SERVICE_DOWN</td>
                            <td>All Partner Types</td>
                            <td>All Products</td>
                            <td>Service down</td>
                            <td>Sorry, CARFAX is unavailable at this time. Please try again later.</td>
                        </tr>
                        <tr>
                            <td>INVALID_AUCTION_CODE</td>
                            <td>Auction</td>
                            <td>Auction Alert</td>
                            <td>Auction Code is incorrectly formatted</td>
                            <td>Incorrectly formatted Auction Code in request.  Please contact Customer Support at your auction software provider.</td>
                        </tr>
                        <tr>
                            <td>INVALID_SALE_DATE</td>
                            <td>Auction</td>
                            <td>Auction Alert</td>
                            <td>Sale Date is incorrectly formatted; should be MM/DD/YYYY</td>
                            <td>Incorrectly formatted Sale Date in request.  Please contact Customer Support at your auction software provider.</td>
                        </tr>
                        <tr>
                            <td>INVALID_STOCK_NUMBER</td>
                            <td>Auction</td>
                            <td>Auction Alert</td>
                            <td>Stock Number is incorrectly formatted</td>
                            <td>Incorrectly formatted Stock Number in request.  Please contact Customer Support at your auction software provider.</td>
                        </tr>
                        <tr>
                            <td>MISSING_AUCTION_CODE</td>
                            <td>Auction</td>
                            <td>Auction Alert</td>
                            <td>Auction Code was not included in the request</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>MISSING_SALE_DATE</td>
                            <td>Auction</td>
                            <td>Auction Alert</td>
                            <td>Sale Date was not included in the request</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>MISSING_STOCK_NUMBER</td>
                            <td>Auction</td>
                            <td>Auction Alert</td>
                            <td>Stock Number was not included in the request</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>UNMATCHED_AUCTION_CODE</td>
                            <td>Auction</td>
                            <td>Auction Alert</td>
                            <td>Unmatched/no Auction Code</td>
                            <td>Your CARFAX account is not configured for this application. Please contact Customer Support at your auction software provider.</td>
                        </tr>
                        <tr>
                            <td>INVALID_ID</td>
                            <td>Auction, Consumer, Dealer, OEM</td>
                            <td>ICR, Report Links, Reviews</td>
                            <td>ID is malformed, unknown, or of the incorrect type</td>
                            <td>ID requested is invalid. Please check and try again.</td>
                        </tr>
                        <tr>
                            <td>INVALID_VIN<br/><br/>Note: Any VIN value of four characters or less will result in a Validation Error instead of an INVALID_VIN error. </td>
                            <td>Auction, Consumer, Dealer, OEM</td>
                            <td>Auction Alert, ICR Report Links, Stoplight</td>
                            <td>VIN is malformed</td>
                            <td>The VIN is not valid. Please enter a valid post 1981 year 17 digit VIN.</td>
                        </tr>
                        <tr>
                            <td>NO_DATA_VIN</td>
                            <td>Auction, Consumer, Dealer, OEM</td>
                            <td>Auction Alert, ICR Report Links, Stoplight</td>
                            <td>VIN is valid but has no displayable records</td>
                            <td>CARFAX does not have any history on this VIN but the VIN is valid.</td>
                        </tr>
                        <tr>
                            <td>UNAUTHORIZED_USER</td>
                            <td>Auction, Dealer, OEM</td>
                            <td>Auction Alert, Report Links, OEM Stoplight</td>
                            <td>User is not authorized to access this product</td>
                            <td>Not authorized to access this product.  Please contact CARFAX for details.</td>
                        </tr>
                        <tr>
                            <td>INACTIVE_DEALER</td>
                            <td>Auction, Dealer, OEM</td>
                            <td>Auction Alert, Report Links</td>
                            <td>Dealer account status is inactive</td>
                            <td>Dealer account is inactive or invalid. Please contact CARFAX for details.</td>
                        </tr><tr>
                            <td>INVALID_ACCOUNT</td>
                            <td>Auction, Dealer, OEM</td>
                            <td>Report Links</td>
                            <td>User account is disabled or otherwise invalid</td>
                            <td>Your CARFAX account is no longer active. Please contact CARFAX for details.</td>
                        </tr>
                        <tr>
                            <td>NO_REPORT_IN_INVENTORY</td>
                            <td>Auction, Dealer, OEM</td>
                            <td>4 Pillar, Report Links</td>
                            <td>Data is not available until CARFAX report is purchased for this account</td>
                            <td>Please purchase a CARFAX Report to view this data.</td>
                        </tr>
                        <tr>
                            <td>UNMATCHED_DEALER</td>
                            <td>Auction, Dealer, OEM</td>
                            <td>Report Links</td>
                            <td>Unmatched Dealer ID</td>
                            <td>No matching dealer found for provided dealer ID. Please contact CARFAX for details.</td>
                        </tr>
                        <tr>
                            <td>UNMATCHED_DEALER_ID</td>
                            <td>Auction, Dealer, OEM</td>
                            <td>Report Links</td>
                            <td>Unmatched/no Dealer ID</td>
                            <td>Your CARFAX account is not configured for this application. Please contact Customer Support for this application.</td>
                        </tr>
                        <tr>
                            <td>CHBV_INSUFFICIENT_DATA</td>
                            <td>Dealer</td>
                            <td>History Based Value</td>
                            <td>Insufficient Data Available.</td>
                            <td>CARFAX cannot provide a value due to insufficient data.</td>
                        </tr>
                        <tr>
                            <td>CHBV_INVALID_ZIP</td>
                            <td>Dealer</td>
                            <td>History Based Value</td>
                            <td>The provided zipCode is not valid.</td>
                            <td>Zip code must be numeric and 5 characters long.</td>
                        </tr>
                        <tr>
                            <td>CHBV_NOT_ADVANTAGE</td>
                            <td>Dealer</td>
                            <td>History Based Value</td>
                            <td>CHBV is only available for advantage dealers.</td>
                            <td>Feature only available for CARFAX Advantage dealers.  Please contact your CARFAX Representative to upgrade your account.</td>
                        </tr>
                        <tr>
                            <td>CHBV_NOT_CONFIGURED</td>
                            <td>Dealer</td>
                            <td>History Based Value</td>
                            <td>CHBV is not configured for this partner.</td>
                            <td>CARFAX History-Based Value has not been configured for this partner.</td>
                        </tr>
                        <tr>
                            <td>CHBV_ODO_SUS</td>
                            <td>Dealer</td>
                            <td>History Based Value</td>
                            <td>There is an odometer discrepancy.</td>
                            <td>There is a discrepancy between the provided odometer reading and the saved odometer reading.</td>
                        </tr>
                        <tr>
                            <td>CHBV_SAVE_FAILURE</td>
                            <td>Dealer</td>
                            <td>History Based Value</td>
                            <td>Save failed</td>
                            <td>Save failed</td>
                        </tr>
                        <tr>
                            <td>CHBV_SAVE_FAILURE</td>
                            <td>Dealer</td>
                            <td>History Based Value</td>
                            <td>Save failed</td>
                            <td>Save Failed - Invalid CARFAX Credentials</td>
                        </tr>
                        <tr>
                            <td>CHBV_VEHICLE_NOT_IN_US</td>
                            <td>Dealer</td>
                            <td>History Based Value</td>
                            <td>Vehicle Not in US</td>
                            <td>CARFAX only provides values on vehicles in the U.S.</td>
                        </tr>
                        <tr>
                            <td>CHBV_YEAR_1999_OR_OLDER</td>
                            <td>Dealer</td>
                            <td>History Based Value</td>
                            <td>Model Year 1999 or older</td>
                            <td>CARFAX only provides values for model years 2000 or newer.</td>
                        </tr><tr>
                            <td>MISSING_FRANCHISE_NUMBER</td>
                            <td>OEM</td>
                            <td>Report Links, OEM Stoplight</td>
                            <td>Invalid or missing franchise number in the request.</td>
                            <td>Sorry, CARFAX is unavailable at this time. Please try again later.</td>
                        </tr>
                        <tr>
                            <td>STOPLIGHT_NOT_ADVANTAGE</td>
                            <td>OEM</td>
                            <td>OEM Stoplight</td>
                            <td>Stoplight is only available for Advantage dealers.</td>
                            <td>Feature only available for CARFAX Advantage dealers. Please contact your CARFAX Representative to upgrade your account.</td>
                        </tr>
                        <tr>
                            <td>INVALID_REVIEW_ID</td>
                            <td>Reputation</td>
                            <td>Reviews</td>
                            <td>The Review ID is invalid or cannot be used in this context.</td>
                            <td>The Review ID requested is invalid.  Please check and try again.</td>
                        </tr>
                        <tr>
                            <td>MISSING_DISPLAY_NAME</td>
                            <td>Reputation</td>
                            <td>Reviews</td>
                            <td>Missing display name in the request.</td>
                            <td>A display name is required to respond to a review.</td>
                        </tr>
                        <tr>
                            <td>REVIEW_VERSION_CONFLICT</td>
                            <td>Reputation</td>
                            <td>Reviews</td>
                            <td>The version provided for this review ID has been updated</td>
                            <td>The Review has been updated since you last retrieved it. Please refresh the review in order to respond to it.</td>
                        </tr>
                        <tr>
                            <td>UNAUTHORIZED_LOCATION</td>
                            <td>Reputation</td>
                            <td>Reviews</td>
                            <td>Partner is not authorized to access this location</td>
                            <td>Not authorized to access this location.</td>
                        </tr>
                        </tbody>
                    </table>
                </section>
            </div>
        );
    }
};

