import React, {Component} from "react";
import "./Authentication.scss";
import ContentTemplate from "../ContentTemplate/ContentTemplate";
import {Image, Table} from "react-bootstrap";
import PartnerPortalUsers from "../../assets/images/partner-portal-users.png";
import PartnerPortalNewApp from "../../assets/images/partner-portal-new-app.png";
import PartnerPortalNewWebApp from "../../assets/images/partner-portal-new-web-app.png";
import ClientCredentialsFlow from "../../assets/images/client-credentials-flow.png";
import imgMobileFlowiOSPlist from "../../assets/images/mobile-flow-ios-plist.png";
import imgMobileUrlSchemeCallback from "../../assets/images/mobile-url-scheme-callback.png";
import AuthCodeFlow from "../../assets/images/auth-code-flow.png";
import LogInMock from "../../assets/images/login-mock.png";
import {HashLink} from "react-router-hash-link";
import Constants from "../Constants";
import CodeSnippetTemplate from "../CodeSnippetTemplate/CodeSnippetTemplate";
import {CodeSnippetLanguages} from "../CodeSnippetTemplate/CodeSnippetLanguages";
import CodeSnippet from "../../model/CodeSnippet";

export default class Authentication extends Component<any, any> {

    private static readonly ANDROID_DEEP_LINKS_URL = "https://developer.android.com/training/app-links/deep-linking"
    private static readonly IOS_DEEP_LINKS_URL = "https://developer.apple.com/documentation/xcode/allowing_apps_and_websites_to_link_to_your_content/defining_a_custom_url_scheme_for_your_app"
    private static readonly PARTNER_PORTAL_URL = Constants.PARTNER_PORTAL_URL_MAP.get(
        Constants.LEVEL
    );
    private sideNavigationLinks: Map<string, string> = new Map([
        ["#partner-portal", "Partner Portal"],
        ["#partner-and-token-types", "Partner and Token Types"],
        ["#client-credentials-flow", "Client Credentials Flow"],
        ["#authorization-code-flow", "Authorization Code Flow"],
        ["#web-application-flow", "Web Application Flow"],
        ["#log-in", "Log In"],
        ["#log-out", "Log Out"],
        ["#mobile-flow-ios", "Mobile Flow - iOS"],
        ["#mobile-flow-android", "Mobile Flow - Android"]
    ]);

    private navLinksMap: Map<string, Map<string, string>> = new Map ([
        ["Authentication", this.sideNavigationLinks]
    ]);

    public render() {
        return (
            <div className={"authentication"}>
                <ContentTemplate
                    pageTitle="Authentication"
                    navlinksMap={this.navLinksMap}
                    content={this.getContentHtml()}
                />
            </div>
        );
    }

    private getContentHtml(): JSX.Element {
        return (
            <div className={"content-container"}>
                <h1 id="authentication">Authentication</h1>
                <section>
                    <h3 id="partner-portal">Partner Portal</h3>
                    <p>
                        When setting up your integration you will provide one Administrator
                        e-mail address to CARFAX. That e-mail address will receive an
                        invitation to the&nbsp;
                        <a href={Authentication.PARTNER_PORTAL_URL} target="_blank" rel="noopener noreferrer">
                            Partner Portal
                        </a>.
                    </p>
                    <p>
                        Within the Partner Portal you can create up to ten accounts. Create a
                        separate account for each administrator to avoid the security risk of
                        sharing credentials and ensure that access can be revoked when an
                        individual no longer needs it. Not every developer needs a Partner
                        Portal account, only those who need to manage application credentials.
                        <br/>
                        <img height="245px"
                            alt="Partner Portal User Admin Screen"
                            src={PartnerPortalUsers}
                        />
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id="partner-and-token-types">Partner and Token Types</h3>
                    <p>
                        CARFAX Connect uses OAuth 2. The correct type of flow to use depends
                        on the use case:
                    </p>
                    <ul>
                        <li>
                            <strong>Client Credentials Flow</strong> is used for
                            machine-to-machine requests where a specific end user is not
                            involved. The partner is calling on their own behalf, typically from
                            a batch application.
                        </li>
                        <li>
                            <strong>Authorization Code Flow</strong> is used when a partner is
                            calling on behalf of a specific CARFAX end user such as a dealer.
                            Users must enter their CARFAX username and password to grant the
                            partner authorization to act on their behalf. CARFAX Connect offers
                            two types of Authorization Code flows designed to accommodate for
                            different Web App architectures:
                            <ul>
                                <li>
                                    <strong>Web Application</strong> is used for applications that
                                    have back-end servers.
                                </li>
                                <li>
                                    <strong>Single Page Application</strong> is used for
                                    applications that have don't have back-end servers and only have
                                    a front-end.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <Table striped bordered id={"flows-use-cases"}>
                        <thead>
                        <tr>
                            <th>Partner Type</th>
                            <th>Application Type</th>
                            <th>Use Case</th>
                            <th>Flow</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Auction</td>
                            <td>Non-interactive applications</td>
                            <td>Retrieve Auction Alert data</td>
                            <td>Client Credentials</td>
                        </tr>
                        <tr>
                            <td>Consumer</td>
                            <td>Non-interactive applications</td>
                            <td>Refresh ICR links &amp; icons</td>
                            <td>Client Credentials</td>
                        </tr>
                        <tr>
                            <td>Reputation Management</td>
                            <td>Non-interactive applications</td>
                            <td>Retrieve and respond to reviews</td>
                            <td>Client Credentials</td>
                        </tr>
                        <tr>
                            <td>Dealer</td>
                            <td>Regular Web App with back-end server</td>
                            <td>
                                Provide CARFAX Report &amp; data to interactive dealer user
                            </td>
                            <td>Auth Code &mdash; Web Application</td>
                        </tr>
                        <tr>
                            <td>Dealer</td>
                            <td>Single Page App with no back-end server</td>
                            <td>
                                Provide CARFAX Report &amp; data to interactive dealer user
                            </td>
                            <td>Auth Code &mdash; Single Page Application</td>
                        </tr>
                        </tbody>
                    </Table>
                </section>
                <hr/>
                <section>
                    <h3 id="client-credentials-flow">Client Credentials Flow</h3>
                    <p>
                        The client credentials flow is used for machine-to-machine (M2M)
                        processes, such as batch applications, where a specific end user is
                        not involved.
                    </p>
                    <p>
                        First create an application in the Partner Portal.
                        <br/>
                        <img alt="Parner Portal Applications Screen" height="255px" src={PartnerPortalNewApp} />
                    </p>
                    <p>
                        After creating the application you will have the client ID and client
                        secret. Protecting the secret is critical. It must be kept in
                        encrypted, least-privilege access storage. Do not store it in plain
                        text. Never embed it in client-side code, such as a mobile application
                        or JavaScript SPA. Do not put it directly in application code.
                    </p>
                    <p>
                        Send the client ID and secret to the authorization server to obtain an
                        access token. Then use the access token to make requests to CARFAX
                        Connect.
                        <img width="550px" height="422px" alt="Client Credentials Flow Sequence Diagram" src={ClientCredentialsFlow} />
                    </p>
                    <p>
                        To obtain an access token, send a <code>POST</code> request to{" "}
                        <code>https://auth.carfax.com/oauth/token</code>:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "cURL",
                                `POST /oauth/token HTTP/1.1 
Host: auth.carfax.com 
Content-Type: application/json 
{
    "client_id": "YOUR_CLIENT_ID",
    "client_secret": "YOUR_CLIENT_SECRET",
    "audience": "https://connect.carfax.com",
    "grant_type": "client_credentials"
}`
                            )
                        ]}/>
                    </p>
                    <p>
                        If the client ID and secret are not valid you will receive a HTTP 401
                        Unauthorized response. If successful you will receive HTTP 200 OK with
                        a JSON body like this:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON",
                                JSON.stringify(
                                    {
                                        access_token: "B2Fd7e1536fA7bfa5671...",
                                        expires_in: 86400,
                                        token_type: "Bearer",
                                    }, null, 4
                                )
                            )
                        ]}/>
                    </p>
                    <p>
                        Use <code>access_token</code> in the <code>Authorization</code> header
                        to make Connect requests:
                        <br/><br/>
                        <pre>
                            Authorization: Bearer YOUR_ACCESS_TOKEN
                        </pre>
                    </p>
                    <p>
                        <strong>IMPORTANT</strong>:  An access token once obtained is valid for 24 hours and
                        should be reused until it expires.  The expiration is identified via &nbsp;
                        <code>expires_in</code> which is in seconds. Be sure to obtain a fresh token sufficiently
                        far in advance to allow for network delays and clock skew. You can have
                        multiple access tokens simultaneously for distributed machines. If coded
                        properly we would expect to see one refresh per day if calling from a single
                        machine or perhaps as many as 10 if using distributed machines to call Connect.
                    </p>
                    <p>
                        Treat the access token as an opaque string. Although it is a JWT (JSON
                        Web Token), do <strong>not</strong> attempt to decode it and extract
                        data from it because we may change the encoding or contents of the
                        token at any time.
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id="authorization-code-flow">Authorization Code Flow</h3>
                    <p>
                        The authorization code flow allows a CARFAX user to authorize
                        interactive partner apps to call CARFAX Connect on their behalf. Users
                        do not enter their credentials directly in the partner app. Rather,
                        the partner app contains a link to a log in page served by CARFAX.
                        Here is a mockup of how these links could appear in a partner app:
                        <br/>
                        <img src={LogInMock} width="381px" height="180px" alt="Mockup of partner app log in link" />
                        <br/>
                        There are two types of Authorization Code flows that CARFAX Connect
                        offers: <strong>Web Application</strong>&nbsp; and{" "}
                        <strong>Single Page Application</strong>. To determine which one to
                        use, please refer&nbsp;
                        <HashLink to={"#partner-and-token-types"} smooth={true}>
                            here
                        </HashLink>.
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id={"web-application-flow"}>Web Application Flow</h3>
                    <p>
                        First register your app in the Partner Portal. For "Application Type"
                        select "Web Application" and enter one or more comma separated
                        callback URLs.
                        <img src={PartnerPortalNewWebApp} width="647px" height="525px" alt="Partner Portal: New Web App Screen" />
                    </p>
                    <p>
                        Following are a few scenarios where you'll need to update the list of callback URLs:
                    </p>
                    <ul>
                        <li>
                            User logs in successfully via CARFAX integration and needs to be directed back to your
                            application.
                        </li>
                        <li>
                            User's token has expired and clicks on View Report link from search result page.
                        </li>
                        <li>
                            URLs have changed following a restructuring of your application or website.
                        </li>
                    </ul>
                    <p>
                        Protecting the client secret is critical. It must be kept in
                        encrypted, least-privilege access storage. Never embed it in
                        client-side code, such as a mobile application or JavaScript SPA. Do
                        not put it directly in application code.
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id="log-in">Log In</h3>
                    <p>
                        When a user clicks the log in link the partner app sends the browser
                        to the authorization URL, which displays a login page served by
                        CARFAX. After users enter their login credentials their browser will
                        be redirected back to a URL provided by the partner.
                        <br/>
                        <img alt="Auth Code Flow Sequence Diagram" src={AuthCodeFlow} height="810px" />
                    </p>
                    <p>
                        <strong>1.</strong> User clicks a link in the partner app to link
                        their CARFAX account.
                    </p>
                    <p>
                        <strong>2.</strong> The link points to the authorization endpoint. Use
                        this URL template to build your link:
                    </p>

                    <pre>
          <code className="curl">
            {`https://auth.carfax.com/authorize
                          ?client_id=YOUR_CLIENT_ID
                          &redirect_uri=YOUR_CALLBACK
                          &state=NONCE
                          &response_type=code
                          &audience=https://connect.carfax.com
                          &scope=offline_access`}
          </code>
        </pre>
                    <p>
                        <code>redirect_uri</code> (also known as the callback) is where the
                        browser will be redirected after the user logs in. It must exactly
                        match one of the callback URLs you entered in the app settings in
                        Partner Portal. Callback URLs should use HTTPS. Although you can
                        append dynamic query parameters, beware the user can tamper with them;
                        instead, it is recommended to use <code>state</code> as a key to
                        retrieve application context.
                    </p>
                    <p>
                        <code>state</code> is a random string that is only used once, called a
                        nonce. It is required to prevent CSRF vulnerabilities and account
                        hijacking. Use a cryptographically secure random number generator to
                        generate an array of at least 128 bits (16 bytes) and then convert it
                        to a string using URL-safe base-64 encoding. Store the nonce in a
                        server side session or client side cookie so you can verify it later.
                        You can also use the nonce as a key to store additional application
                        context needed to seamlessly complete a user initiated action after
                        login. For more information, see:
                    </p>
                    <p>
                        <code>audience</code> is a fixed string ("https://connect.carfax.com")
                        required as part of the login / access token process. It is only required for the
                        request for the ACCESS token but is OPTIONAL for the REFRESH token request.

                    </p>
                    <ul>
                        <li>
                            <a target={"_blank"} rel={"noopener noreferrer"} href="https://auth0.com/docs/protocols/state-parameters#csrf-attacks">
                                Mitigate CSRF Attacks with State Parameters (Auth0.com)
                            </a>
                        </li>
                        <li>
                            <a target={"_blank"} rel={"noopener noreferrer"} href="https://auth0.com/docs/protocols/state-parameters#redirect-users">
                                Redirect Users With State Parameters (Auth0.com)
                            </a>
                        </li>
                    </ul>

                    <p>
                        All parameter values must be individually URL encoded before inserting
                        them into the URL template. If using JavaScript, use{" "}
                        <code>encodeURIComponent</code>.
                    </p>
                    <p>
                        <strong>3-6.</strong> The request to the authorization endpoint will
                        result in a redirect to a login page where the user enters
                        credentials. There may be several screens in this sequence, including
                        a Terms and Conditions page, which are not shown in the diagram for
                        simplicity. All of this interaction is handled by CARFAX's
                        authorization server.
                    </p>
                    <p>
                        <strong>7-8.</strong> After the user authorizes the partner app, the
                        browser is redirected to your callback, which is the URL you provided
                        as <code>redirect_uri</code>. The browser makes a <code>GET</code>{" "}
                        request to this URL with <code>code</code> and <code>state</code>{" "}
                        query parameters.
                    </p>
                    <p>
                        For example, if your callback URL is{" "}
                        <code>https://example.com/callback</code>, the browser would make a
                        request like this:
                    </p>
                    <p>
                        <code>
                            GET https://example.com/callback?code=CODE&amp;state=NONCE
                        </code>
                    </p>
                    <p>
                        <code>state</code> is the same random value you provided in the
                        authorization call. For security, you <strong>must</strong> verify the
                        nonce matches the original value. If it does not, display an error.
                    </p>
                    <p>
                        <code>code</code> is the authorization code to be used in the next
                        step.
                    </p>
                    <p>
                        <strong>9.</strong> Obtain an access token by sending a form{" "}
                        <code>POST</code> to the token endpoint,{" "}
                        <code>https://auth.carfax.com/oauth/token</code>. Include the header{" "}
                        <code>Content-Type: application/x-www-form-urlencoded</code>.
                        Form data:
                        <Table striped bordered>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <code>grant_type</code>
                                </td>
                                <td>
                                    <code>authorization_code</code>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <code>code</code>
                                </td>
                                <td>
                                    authorization code from the <code>code</code> query string
                                    parameter in the previous step
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <code>redirect_uri</code>
                                </td>
                                <td>
                                    must be the same callback URL used in the initial authorization
                                    request
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <code>client_id</code>
                                </td>
                                <td>app client ID from Partner Portal</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>client_secret</code>
                                </td>
                                <td>app client secret from Partner Portal</td>
                            </tr>
                            </tbody>
                        </Table>
                        All values must be form encoded.
                    </p>
                    <p>
                        <strong>10.</strong> If the above request is successful you will
                        receive an HTTP 200 OK status with a JSON body like this:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON",
                                JSON.stringify(
                                    {
                                        access_token: "eyJ0eXAiOiJKV1QiLCJhb...",
                                        refresh_token:
                                            "MjBlZDA0OGViZjEyZjg0ZmEzNGE3ZjEwNWU0ZTU0M2UK...",
                                        scope: "offline_access",
                                        expires_in: 86400,
                                        token_type: "Bearer",
                                    }, null, 4
                                )
                            )
                        ]}/>
                    </p>
                    <p>
                        Treat the access token as an opaque string. Although it is a JWT (JSON
                        Web Token), do <strong>not</strong> attempt to decode it and extract
                        data from it because we may change the encoding or contents of the
                        token at any time.
                    </p>
                    <p>
                        <strong>11.</strong> Update your UI to let the user know the
                        authorization succeeded.
                    </p>
                    <p>
                        <strong>12.</strong> Make requests to CARFAX Connect including the{" "}
                        <code>access_token</code> in the <code>Authorization</code> header:
                    </p>
                    <pre>
                    Authorization: Bearer YOUR_ACCESS_TOKEN
                </pre>
                    <p>
                        You can make <a href="send-queries#batch-requests">batch requests</a>,
                        but you cannot mix queries for different users in the same batch
                        because each HTTP request has only one <code>Authorization</code>{" "}
                        header.
                    </p>
                </section>
                <hr/>
                <section>
                    <h4 id="refreshing-tokens">Refreshing Tokens</h4>
                    <p>
                        <strong>13.</strong> The access token expires after{" "}
                        <code>expires_in</code> seconds. You can obtain a fresh access token
                        by passing the <code>refresh_token</code> to the token endpoint,{" "}
                        <code>https://auth.carfax.com/oauth/token</code>. Include the header{" "}
                        <code>Content-Type: application/x-www-form-urlencoded</code>.
                        Form data:
                        <Table striped bordered>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Value</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>
                                    <code>grant_type</code>
                                </td>
                                <td>
                                    <code>refresh_token</code>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <code>refresh_token</code>
                                </td>
                                <td>
                                    value of <code>refresh_token</code> from the original token
                                    response
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <code>client_id</code>
                                </td>
                                <td>app client ID from Partner Portal</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>client_secret</code>
                                </td>
                                <td>app client secret from Partner Portal</td>
                            </tr>
                            <tr>
                                <td>
                                    <code>audience</code>&nbsp;(optional)
                                </td>
                                <td>
                                    <code>https://connect.carfax.com</code>
                                </td>
                            </tr>
                            </tbody>
                        </Table>

                        Below is a cURL example of a refresh token exchange:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JAVA,
                                "cURL",
                                "curl --request POST \\\n" +
                                "--url 'https://auth.carfax.com/oauth/token' \\\n" +
                                "--header 'Content-Type: application/x-www-form-urlencoded' \\\n" +
                                "--data grant_type=refresh_token \\\n" +
                                "--data refresh_token=asdfghjkl \\\n" +
                                "--data client_id=your_client_id \\\n" +
                                "--data client_secret=secret \\"
                            )
                        ]}/>
                    </p>
                    <p>
                        <strong>14.</strong> If the refresh request is successful, the
                        response will be HTTP 200 OK with a JSON body like this:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON",
                                JSON.stringify(
                                    {
                                        access_token: "eyJ0eXAiOiJKV1QiLCJhb...",
                                        refresh_token: "MjBlZDA0OGViZjEyZjg0ZmEzNGE3ZjEwNWU0ZTU0M2UK...",
                                        scope: "offline_access",
                                        expires_in: 86400,
                                        token_type: "Bearer",
                                    }, null, 4
                                )
                            )
                        ]}/>
                    </p>
                    <p>
                        Notice that the response format is the same as the original token response including
                        the <code>refresh_token</code>.
                    </p>
                    <p>
                        <strong>
                            IMPORTANT: Refresh tokens expire after 30 days but may only be used once to fetch a new access
                            token. It is this new one-time use of the refresh token that allows for user activity to be
                            tracked over 30 days. Users who do not use CARFAX for 30 consecutive days should be prompted
                            to log in again as the refresh token expires after 30 days of inactivity.
                        </strong>
                    </p>
                </section>
                <hr/>
                <section>
                <h3 id="log-out">Log Out</h3>
                <p>
                    As shown in the{" "}
                    <a href="#authorization-code-flow">CARFAX user information mockup</a>,
                    you should provide a way for a user to log out. This will disconnect
                    the user's CARFAX account from your application.
                </p>
                <p>
                    <strong>1.</strong> Revoke refresh token
                    <br />
                    A refresh token allows a user to obtain renewed access token until the refresh token is invalidated or revoked.
                    Hence for a user to successfully logout, the refresh token needs to be revoked. To do that, send a <code>POST</code> request
                    to <code>https://auth.carfax.com/oauth/revoke</code>:
                <CodeSnippetTemplate codeSnippets={[
                    new CodeSnippet(CodeSnippetLanguages.JSON,
                        "cURL",
                        `POST /oauth/revoke HTTP/1.1 
Host: auth.carfax.com 
Content-Type: application/json 
{
    "client_id": "YOUR_CLIENT_ID",
    "client_secret": "YOUR_CLIENT_SECRET",
    "token": "YOUR_REFRESH_TOKEN"
}`
                    )
                ]}/>
                </p>
                    <p>On a successful request, you will receive an HTTP 200 OK status.</p>
                    <p>
                        <strong>2.</strong> Delete all access and refresh tokens
                        <br />
                        After revoking a refresh token, delete any access or refresh tokens you may have stored for that user in your
                    application like in the database, the browser cookies or the mobile's keychain storage. This completes the log out process. If you wish to
                    redirect the user back to CARFAX login screen when user tries to login
                    again, redirect the user to the following URL on logout.
                        <br />
                        <code>
                            {`https://auth.carfax.com/v2/logout`}
                        </code>
                        <br />
                        <br />
                        If you wish to redirect the user to a custom page of your choosing, use the following URL for the logout process. <br />
                        <code>
                            {`https://auth.carfax.com/v2/logout?client_id=YOUR_CLIENT_ID&returnTo=YOUR_LOGOUT_URL`}
                        </code>
                        <br />
                        <br />
                        Please make sure that the logout URL that you specify here (YOUR_LOGOUT_URL) is the same URL you have specified in the Partner Portal 'Allowed Logout URLs' section for the client you are using.
                        Use the Client ID from the Partner Portal as the 'YOUR_CLIENT_ID' in the logout URL.  For more information on this, please refer to&nbsp;
                        <a href="https://auth0.com/docs/logout/redirect-users-after-logout" target={"_blank"} rel={"noopener noreferrer"}>Redirect Users</a>
                    </p>
                <p>
                    Note: Revoking one refresh token simultaneously revokes all other refresh
                    tokens for the same user in the application. Revoking a refresh token
                    for a particular user does not affect other users in the application,
                    nor does it affect that particular user in applications that use a
                    different client ID.
                </p>
                </section>
                <hr/>
                <section>
                <h3 id={"mobile-flow-ios"}>Mobile Flow - iOS</h3>
                <p>
                    The following is the recommended method for implementing the Authentication flow
                    on iOS mobile devices. The purpose of this method is to enable Single Sign-On for
                    multiple apps utilizing CARFAX Connect on the same mobile device. The implementation
                    consists of utilizing iOS Deep Links for which more documentation can be found&nbsp;
                    <a href={Authentication.IOS_DEEP_LINKS_URL} target={"_blank"} rel={"noopener noreferrer"}>here.</a>
                </p>
                <h4>Configuration</h4>
                    <ol>
                        <li>

                            Modify your info.plist file to contain a URL Scheme and URL Identifier. , and the URL Scheme
                            must be
                            a unique string.
                            <ul>
                                <li>
                                    The URL Identifier is the unique string that will distinguish your app from other
                                    apps
                                    utilizing the same scheme. The recommendation is to have it be the same as your
                                    bundle identifier which is unique to the app.
                                </li>
                                <li>The URL Scheme will be the protocol that will be part of the
                                    <code>redirect_uri</code> parameter when authenticating. This also needs to be
                                    unique
                                    to ensure that only your app can handle that specific redirect.
                                </li>
                            </ul>
                            <Image src={imgMobileFlowiOSPlist} fluid/>
                        </li>
                        <li>
                            Register your URL Scheme in the Callback URLs of your Application in the Partner Portal.
                            <Image src={imgMobileUrlSchemeCallback} fluid/>
                        </li>
                    </ol>
                    <h4>Initiating the Flow</h4>
                    <ol>
                        <li>
                            Redirect the user to the login page by opening the URL in the mobile browser.
                            <CodeSnippetTemplate codeSnippets={[
                                new CodeSnippet(CodeSnippetLanguages.SWIFT,
                                    "Swift",
                                    `let authorizeUrl = "https://auth.carfax.com/authorize?client_id=YOUR_CLIENT_ID&redirect_uri=yourapplicationcallbackurl://host&callback&state=random_generated_string&response_type=code&audience=https://connect.carfax.com&scope=offline_access"

if let url = URL(string: authorizeUrl) {
    UIApplication.shared.open(url, options: [:], completionHandler: nil)
}`
                                )
                            ]}/>
                        </li>
                        <li>
                            Handle the mobile browser redirect in your app. Apple provides two locations where the
                            event can be sent to your app:
                            <ul>
                                <li>
                                    If you have not opted into using&nbsp;
                                    <a href={"https://developer.apple.com/documentation/uikit/uiscenedelegate"}
                                       target={"_blank"} rel={"noopener noreferrer"}>
                                        Scenes
                                    </a> which is a feature of iOS 13, then the event needs to be handled in the
                                    AppDelegate:
                                    <br/>
                                    <CodeSnippetTemplate codeSnippets={[
                                        new CodeSnippet(CodeSnippetLanguages.SWIFT,
                                            "Swift",
                                            `func application(_ application: UIApplication, open url: URL, options: [UIApplication.OpenURLOptionsKey : Any] = [:] ) -> Bool {
    print("url = \\(url)")

    guard let components = NSURLComponents(url: url, resolvingAgainstBaseURL: true), let params = components.queryItems else {
        return false
    }

    if let code = params.first(where: { $0.name == "code" })?.value {
        print("code = \\(code)")

        // Fetch Access Token and proceed to next screen.
        return true
    }
    else {
        print("code not found")
        
        return false
    }
}`
                                        )
                                    ]}/>
                                </li>
                                <li>
                                    If you have opted into using Scenes, then the event needs to be handled in the
                                    SceneDelegate:
                                    <br/>
                                    <CodeSnippetTemplate codeSnippets={[
                                        new CodeSnippet(
                                            CodeSnippetLanguages.SWIFT,
                                            "Swift",
                                            `func scene(_ scene: UIScene, openURLContexts URLContexts: Set<UIOpenURLContext>) {
    if let urlContext = URLContexts.first {
        let url = urlContext.url
        print("url = \\(url)")
        
        guard let components = NSURLComponents(url: url, resolvingAgainstBaseURL: true), let params = components.queryItems else {
            return
        }
        
        if let code = params.first(where: { $0.name == "code" })?.value {
            print("code = \\(code)")
            
            // Fetch Access Token and proceed to next screen.
        }
        else {
            print("code not found")
        }
    }
}`
                                        )
                                    ]}/>
                                </li>
                            </ul>
                        </li>
                        <li>
                            Extract the <code>code</code> parameter from the URL query string.
                        </li>
                        <li>
                            The flow will now follow the same path as a normal Web Application flow. Have your backend
                            server get the Access Token which then can be used to query Connect.
                        </li>
                    </ol>
                </section>
                <hr/>
                <section>
                <h3 id={"mobile-flow-android"}>Mobile Flow - Android</h3>
                <p>
                    The following is the recommended method for implementing the Authentication flow
                    on Android mobile devices. The purpose of this method is to enable Single Sign-On for
                    multiple apps utilizing CARFAX Connect on the same mobile device. The implementation
                    consists of utilizing Android Deep Links for which more documentation can be found&nbsp;
                    <a href={Authentication.ANDROID_DEEP_LINKS_URL} target={"_blank"}
                       rel={"noopener noreferrer"}>here.</a>
                </p>
                    <h4>Configuration</h4>
                    <ol>
                        <li>
                            Modify your AndroidManifest.xml file to contain an intent filter within an activity that
                            would handle the custom URL scheme.
                            <CodeSnippetTemplate codeSnippets={[
                                new CodeSnippet(CodeSnippetLanguages.MARKUP,
                                    "XML",
                                    `<intent-filter>
    <action android:name="android.intent.action.VIEW" />
    <category android:name="android.intent.category.DEFAULT" />
    <category android:name="android.intent.category.BROWSABLE" /> 
    <data android:scheme="yourapplicationcallbackurl" android:host="host" />
</intent-filter>`
                                )
                            ]}/>
                        </li>
                        <li>
                            Register your URL Scheme in the Callback URLs of your Application in the Partner Portal.
                            <Image src={imgMobileUrlSchemeCallback} fluid/>
                        </li>
                    </ol>
                    <h4>Initiating the Flow</h4>
                    <ol>
                        <li>
                            Redirect the user to the login page by opening the URL in the mobile browser.
                            <CodeSnippetTemplate codeSnippets={[
                                new CodeSnippet(CodeSnippetLanguages.KOTLIN,
                                    "Kotlin",
                                    `val authorizeUrl = "https://auth.carfax.com/authorize?client_id=YOUR_CLIENT_ID&redirect_uri=yourapplicationcallbackurl://host&callback&state=random_generated_string&response_type=code&audience=https://connect.carfax.com&scope=offline_access"
val browserIntent = Intent(Intent.ACTION_VIEW, Uri.parse(authorizeUrl))

startActivity(browserIntent)`
                                )
                            ]}/>
                        </li>
                        <li>
                            Handle the mobile browser redirect in your app. Android will send the event to the activity
                            that contains the intent filter in the AndroidManifest.xml.
                            <CodeSnippetTemplate codeSnippets={[
                                new CodeSnippet(CodeSnippetLanguages.KOTLIN,
                                    "Kotlin",
                                    `override fun onCreate(savedInstanceState: Bundle?) {
    super.onCreate(savedInstanceState)
    setContentView(R.layout.main)
    val action: String? = intent?.action
    val data: Uri? = intent?.data
    val code = data?.getQueryParameter("code")
    println ("Code:"+ code)
    
    // Fetch Access Token and proceed to next screen.
}`
                                )
                            ]}/>
                        </li>
                        <li>
                            Extract the <code>code</code> parameter from the URL query string.
                        </li>
                        <li>
                            The flow will now follow the same path as a normal Web Application flow. Have your backend
                            server get the Access Token which then can be used to query Connect.
                        </li>
                    </ol>
                </section>
            </div>
        );
    }
}
