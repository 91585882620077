import React, {Component, RefObject} from 'react';
import './Navbar.scss';
import {Nav, Navbar as BootstrapNavbar} from "react-bootstrap";
import {ReactComponent as Logo} from "../../assets/images/CARFAX_Connect_logo_R_black.svg";
import Constants from "../Constants";
import {Link} from "react-router-dom";
import {TriangleDown} from "@carfax-stencils/icon";

interface State {
    hiddenDropDown: string | null
    activeDropDown: string | null
}

interface Props {

}

export default class Navbar extends Component<Props, State> {

    private static readonly GETTING_STARTED_DROPDOWN_TARGET = "getting-started"
    private static readonly BASICS_DROPDOWN_TARGET = "basics"
    private static readonly PARTNER_TYPE_DROPDOWN_TARGET = "partner-type"
    private static readonly PARTNER_PORTAL_URL = Constants.PARTNER_PORTAL_URL_MAP.get(Constants.LEVEL);
    private readonly componentRef: RefObject<any>

    public constructor(props: Props) {
        super(props)

        this.componentRef = React.createRef();
        this.outsideComponentClick = this.outsideComponentClick.bind(this)
        this.hideDropDowns = this.hideDropDowns.bind(this)
        this.showOrHideDropDowns = this.showOrHideDropDowns.bind(this)

        this.state = {
            hiddenDropDown: null,
            activeDropDown: null
        }
    }

    public componentDidMount() {
        document.addEventListener("mousedown", this.outsideComponentClick)
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.outsideComponentClick)
    }

    public render() {
        let gettingStartedDropDownClass = this.getActiveDropDownClass(Navbar.GETTING_STARTED_DROPDOWN_TARGET)
        let basicsDropDownClass = this.getActiveDropDownClass(Navbar.BASICS_DROPDOWN_TARGET)
        let partnerTypeDropDownClass = this.getActiveDropDownClass(Navbar.PARTNER_TYPE_DROPDOWN_TARGET)

        return (
            <div className={"cd-navbar sticky-top"} ref={this.componentRef}>
                <BootstrapNavbar className={"nav-container"} variant={"light"} expand={"lg"}>
                    <BootstrapNavbar.Brand href="/"><Logo className={"navbar-logo"}/></BootstrapNavbar.Brand>
                    <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav">
                        <span />
                        <span />
                        <span />
                    </BootstrapNavbar.Toggle>
                    <BootstrapNavbar.Collapse id="basic-navbar-nav">
                        <Nav className={"mr-auto"}>
                            <div className={"nav-item-container"}>
                                <Link to={Constants.HOME_PAGE_URL}
                                      className={"nav-link nav-item text-white"}
                                      onClick={() => this.hideDropDowns()}>Overview</Link>
                            </div>
                            <div className={"nav-item-container"}>
                                <div
                                    className={"cd-dropdown nav-item nav-link text-white"}
                                    onClick={() => this.showOrHideDropDowns(Navbar.GETTING_STARTED_DROPDOWN_TARGET)}>
                                    <span>Getting Started <TriangleDown /></span>
                                    <div
                                        className={`cd-dropdown-menu ${this.state.activeDropDown === null ? "" : gettingStartedDropDownClass} `}>
                                        <Link to={Constants.GETTING_STARTED_AUCTION_PARTNER} className={"dropdown-item"}
                                              onClick={() => this.hideDropDowns()}>Auction Partner</Link>
                                        <Link to={Constants.GETTING_STARTED_CONSUMER_URL} className={"dropdown-item"}
                                              onClick={() => this.hideDropDowns()}>Consumer Partner</Link>
                                        <Link to={Constants.GETTING_STARTED_DEALER_URL}
                                              className={"dropdown-item"}
                                              onClick={() => this.hideDropDowns()}>Dealer Partner</Link>
                                    </div>
                                </div>
                            </div>
                            <div className={"nav-item-container"}>
                                <div
                                    className={"cd-dropdown nav-item nav-link text-white"}
                                    onClick={() => this.showOrHideDropDowns(Navbar.BASICS_DROPDOWN_TARGET)}>
                                    <span>Basics <TriangleDown /></span>
                                    <div
                                        className={`cd-dropdown-menu ${this.state.activeDropDown === null ? "" : basicsDropDownClass}`}>
                                        <Link to={Constants.SEND_QUERIES_PAGE_URL}
                                              className={"dropdown-item"}
                                              onClick={() => this.hideDropDowns()}>Sending Queries</Link>
                                        <Link to={Constants.AUTHENTICATION_PAGE_URL} className={"dropdown-item"}
                                              onClick={() => this.hideDropDowns()}>Authentication</Link>
                                        <Link to={Constants.ERROR_HANDLING_PAGE_URL} className={"dropdown-item"}
                                              onClick={() => this.hideDropDowns()}>Error Handling</Link>
                                        <Link to={Constants.PAGINATION_PAGE_URL}
                                              className={"dropdown-item"}
                                              onClick={() => this.hideDropDowns()}>Pagination</Link>
                                        <Link to={Constants.SSO_IMPLEMENTATION_URL}
                                              className={"dropdown-item"}
                                              onClick={() => this.hideDropDowns()}>Single Sign-On</Link>
                                        <Link to={Constants.RATE_QUOTA_LIMITS_URL}
                                              className={"dropdown-item"}
                                              onClick={() => this.hideDropDowns()}>Rate & Quota Limits</Link>
                                    </div>
                                </div>
                            </div>
                            <div className={"nav-item-container"}>
                                <div
                                    className={"cd-dropdown nav-item nav-link text-white"}
                                    onClick={() => this.showOrHideDropDowns(Navbar.PARTNER_TYPE_DROPDOWN_TARGET)}>
                                    <span>Partner Type <TriangleDown /></span>
                                    <div
                                        className={`cd-dropdown-menu ${this.state.activeDropDown === null ? "" : partnerTypeDropDownClass}`}>
                                        <Link to={Constants.AUCTION_PARTNER_PAGE_URL}
                                              className={"dropdown-item"}
                                              onClick={() => this.hideDropDowns()}>Auction</Link>
                                        <Link to={Constants.CONSUMER_PAGE_URL}
                                              className={"dropdown-item"}
                                              onClick={() => this.hideDropDowns()}>Consumer</Link>
                                        <Link to={Constants.DEALER_PAGE_URL}
                                              className={"dropdown-item"}
                                              onClick={() => this.hideDropDowns()}>Dealer</Link>
                                        <Link
                                            to={Constants.OEM_PARTNER_PAGE_URL} 
                                            className={"dropdown-item"}
                                            onClick={() => this.hideDropDowns()}>OEM</Link>
                                        <Link
                                            to={Constants.REPUTATION_MANAGEMENT_PAGE_URL}
                                            className={"dropdown-item"}
                                            onClick={() => this.hideDropDowns()}>Reputation Management</Link>
                                    </div>
                                </div>
                            </div>
                            <div className={"nav-item-container"}>
                                <Link to={Constants.FAQ_URL}
                                      className={"nav-link nav-item text-white"}
                                      onClick={() => this.hideDropDowns()}>FAQ</Link>
                            </div>
                            <div className={"nav-item-container"}>
                                <Link to={Constants.RECENT_UPDATES_URL}
                                      className={"nav-link nav-item text-white"}
                                      onClick={() => this.hideDropDowns()}>Recent Updates</Link>
                            </div>
                        </Nav>
                        <Nav>
                            <div className={"nav-item-container"}>
                                <Nav.Link href={Navbar.PARTNER_PORTAL_URL}
                                          className={"nav-item text-white"}
                                          target={"_blank"}>Partner Portal</Nav.Link>
                            </div>
                            <div className={"vl"} />
                            <div className={"nav-item-container"}>
                                <Nav.Link href={Constants.CONNECT_EXPLORER_URL}
                                      className={"nav-item text-white"}
                                      target={"_blank"}>Connect Explorer</Nav.Link>
                            </div>
                        </Nav>
                    </BootstrapNavbar.Collapse>
                </BootstrapNavbar>
            </div>
        )
    }

    private outsideComponentClick(event: MouseEvent) {
        if (this.componentRef && !this.componentRef.current?.contains(event.target as Node)) {
            this.hideDropDowns()
        }
    }

    private hideDropDowns() {
        this.setState({
            hiddenDropDown: this.state.activeDropDown,
            activeDropDown: ""
        })
    }

    private showOrHideDropDowns(target: string) {
        if(this.state.activeDropDown === target) {
            this.setState({
                hiddenDropDown: target,
                activeDropDown: ""
            })
        }
        else {
            this.setState({
                hiddenDropDown: this.state.activeDropDown,
                activeDropDown: target
            })
        }
    }

    private getActiveDropDownClass(target: string): string {
        let dropdownHideClass = "hide"
        let dropdownShowClass = "show"

        if(this.state.activeDropDown === target) {
            return dropdownShowClass
        }
        else if(this.state.hiddenDropDown === target) {
            return dropdownHideClass
        }
        else {
            return ""
        }
    }
}
