import React, {Component} from "react";
import ContentTemplate from "../ContentTemplate/ContentTemplate";
import RateQuoteLimImg from "../../assets/images/rate-quota-limits.png";

interface State {
}

interface Props {

}

export default class RateQuotaLimits extends Component<Props, State> {

    public constructor(props: Props) {
        super(props);
    }

    private sideNavigationLinks: Map<string, string> = new Map([
        ["#overview", "Overview"],
        ["#what-is-rate-limit", "What is a rate limit?"],
        ["#how-is-rate-limit-calculated", "How are rate limits calculated?"],
        ["#specific-rate-limits", "What are the specific rate limits associated with my Connect app and products?"],
        ["#detect-and-respond", "How do I detect and respond to Connect being throttled?"],
        ["#burst-limit-for-products", "What is the burst limit for my Connect app and products?"],
        ["#batch-adjust", "I typically make batch requests of 100 queries.  Will I need to adjust this?"],
        ["#quota-limit-for-connect-app", "What is the Quota Limit for my Connect app and products?"]

    ]);

    private navLinksMap: Map<string, Map<string, string>> = new Map([
        ["Rate & Quota Limits", this.sideNavigationLinks]
    ]);

    public render() {
        return (
            <div className={"rate-quota-limits"}>
                <ContentTemplate
                    pageTitle="Rate Quota Limits"
                    navlinksMap={this.navLinksMap}
                    content={this.getContentHtml()}
                />
            </div>
        )
    }

    private getContentHtml(): JSX.Element {
        return (
            <div className={"content-container"}>
                <h1>Rate &amp; Quota Limits</h1>
                <section>
                    <h3 id="overview">Overview</h3>
                    <p>
                        CARFAX Connect utilizes rate and quota limits to protect server infrastructure from abuse or misuse.
                        These limits enable consistent load allocation across our platform.
                        Please account for the rate and quota limits in your coding of Connect to avoid any possible disruptions to end users.
                    </p>
                    <hr/>
                </section>

                <section>
                    <h3 id="what-is-rate-limit">What is a rate limit?</h3>
                    <p>
                        A "rate limit" is a policy that affects the frequency Connect may be called.
                        The rate limit is the number of requests that may be made per second to receive a
                        consistent response from Connect based on the number of tokens available in your bucket.
                        This rate also reflects how many tokens are added back to your bucket per second to be made available for new requests.
                    </p>
                    <hr/>
                </section>

                <section>
                    <h3 id="how-is-rate-limit-calculated">How are rate limits calculated?</h3>
                    <p>
                        Each product offered through CARFAX Connect has a default rate limit based upon
                        the most common usage patterns associated with the given product.
                        This allows CARFAX to better manage and distribute potential load across the
                        platform to better secure and protect it.  Please note that rate limits can be
                        customized and therefore may vary from partner to partner by product.
                    </p>
                    <hr/>
                </section>

                <section>
                    <h3 id="specific-rate-limits">What are the specific rate limits associated with my Connect app and products?</h3>
                    <p>
                        You can find your app's specific rate limits by logging into the Partner Portal and viewing the Rate/Quota Limits tab.
                        In addition to the default limits, your account manager has the ability to modify rate limits on an app/product basis
                        in order to better serve the unique needs of your situation.
                    </p>
                    <img
                        src={RateQuoteLimImg}
                        alt="Connect: Rate/Quota Limits Table"
                    />
                    <hr/>
                </section>

                <section>
                    <h3 id="detect-and-respond">How do I detect and respond to Connect being throttled?</h3>
                    <p>
                        When Connect exceeds its rate limit, the platform will begin to throttle the app,
                        prohibiting more API calls from being made. When this happens, any call to Connect will result in a failure,
                        with a RATE_LIMIT_EXCEEDED error code being returned.
                    </p>
                    <hr/>
                </section>

                <section>
                    <h3 id="burst-limit-for-products">What is the burst limit for my Connect app and products?</h3>
                    <p>
                        A "burst limit" is the maximum number of concurrent requests that may be made at any moment.
                        A burst request will be processed using all available tokens in your assigned bucket when submitted.
                        In order for new requests to process tokens must be available and are refreshed at your assigned rate limit.
                        Thus, a burst request may result in a waiting period.  For example, a burst of 20 requests associated with a
                        rate limit of 1 per second will be processed completely assuming 20 tokens are available.
                        Single requests may be subsequently made but another burst of 20 requests cannot be processed until
                        20 tokens are available again after 20 seconds. Each product offered through CARFAX Connect has a
                        default burst rate limit based upon the most common usage patterns associated with the given product.
                        This allows CARFAX to better manage and distribute potential load across the platform to better secure and protect it.
                        Please note that burst rate limits can be customized and therefore may vary from partner to partner by product.
                    </p>
                    <hr/>
                </section>

                <section>
                    <h3 id="batch-adjust">I typically make batch requests of 100 queries.  Will I need to adjust this?</h3>
                    <p>
                        Yes, if your given product has a burst limit of less than 100.
                        For example, if your burst limit is 20 with a rate limit of 5 and you submit a
                        batch request of 100 then only the first 20 queries will process.
                        But you can subsequently make 5 more requests each second following this or
                        pause for 4 seconds after which the full burst limit of 20 will again be available.
                    </p>
                    <hr/>
                </section>

                <section>
                    <h3 id="quota-limit-for-connect-app">What is the Quota Limit for my Connect app and products?</h3>
                    <p>
                        A “quota limit” is the maximum number of requests that may be made in a calendar day.
                        Exceeding this limit may result in access to Connect being suppressed.
                        If you feel the quota limit is not appropriate for your business needs please contact your account manager.
                    </p>
                    <hr/>
                </section>

            </div>
        )
    }
}