import React, {Component} from 'react';
import './ReputationManagement.scss';
import ContentTemplate from "../ContentTemplate/ContentTemplate";
import CodeSnippetTemplate from "../CodeSnippetTemplate/CodeSnippetTemplate";
import {CodeSnippetLanguages} from "../CodeSnippetTemplate/CodeSnippetLanguages";
import CodeSnippet from "../../model/CodeSnippet";

export default class ReputationManagement extends Component<any, any> {

    private sideNavigationLinks: Map<string, string> = new Map([
        ["#list-locations", "List Locations"],
        ["#fetch-reviews", "Fetch Reviews"],
        ["#respond-to-a-review", "Respond to a Review"]]);

    private navLinksMap: Map<string, Map<string, string>> = new Map ([
        ["Recent Updates", this.sideNavigationLinks]
    ]);

    public render() {
        return (
            <div className={"reputation-management"}>
                <ContentTemplate
                    pageTitle="Recent Updates"
                    navlinksMap={this.navLinksMap}
                    content={this.getContentHtml()}/>
            </div>
        );
    }

    private getContentHtml(): JSX.Element {

        return (
            <div className={"content-container"}>
                <h1 id="reputation-mngment">Reputation Management</h1>
                <section>
                <h3 id="list-locations">List Locations</h3>
                <p>Reviews at CARFAX can either be for a service shop or a car dealership.
                    The term <em>location</em> is used abstractly to refer to either of these.
                    You can only access locations for which you are the designated
                    reputation management partner. To retrieve these locations, use
                    the field <code>viewer.locationsManagedByReputationPartner</code>. This is
                    a <a href="pagination">paginated</a> field. The <code>first</code> argument
                    has a maximum value of 100.</p>
                <p>Example query:
                <CodeSnippetTemplate codeSnippets={[
                    new CodeSnippet(CodeSnippetLanguages.GRAPHQL,
                        "GraphQL", `query ($after: Cursor) {
    viewer {
        locationsManagedByReputationPartner(first: 100, after: $after) {
            nodes {
            id
            name
            address {
                city
                state
                postalCode
                streetAddress1
            }
            webUrl
            phoneNumber
            coordinates {
                latitude
                longitude
            }
        }
            pageInfo {
                hasNextPage
                endCursor
            }
        }
    }
}`
                    )
                ]} />
                </p>
                </section>
                <hr/>
                <section>
                <h3 id="fetch-reviews">Fetch Reviews</h3>
                <p>Get a specific <code>location</code> by <code>id</code>, then query its <code>reviews</code> field.
                    <code>reviews</code> is a <a href="pagination">paginated</a> field with
                    a <code>first</code> maximum value of 100.
                    You can query multiple locations in a single HTTP
                    <a href="send-queries#batch-requests"> batch request</a>.</p>
                <p>The <code>reviews</code> field returns the <code>Review</code> interface type, which has different
                    concrete implementations depending on the subject of the review. Sales
                    experiences are represented by <code>DealerSalesReview</code> with details in the
                    associated sales <code>lead</code>. Service shop visits are represented by
                    <code>ServiceReview</code> with a linked <code>service</code> record. You can use the
                    <code>__typename</code> <a href="https://graphql.org/learn/queries/#meta-fields" target="_blank" rel="noopener noreferrer">meta field </a>
                    to determine the type of each review and
                    <a href="https://graphql.org/learn/queries/#inline-fragments" target="_blank" rel="noopener noreferrer"> inline fragments </a>
                    to get type specific details. Beware that additional review types may be
                    added in the future.</p>
                <p>This example query shows the full set of fields available for all
                    review types. However, you should only query fields you will
                    actually use:
                <CodeSnippetTemplate codeSnippets={[
                    new CodeSnippet(CodeSnippetLanguages.GRAPHQL,
                        "GraphQL", `query ($id: ID!, $after: Cursor) {
  location(id: $id) {
    reviews(first: 100, after: $after) {
      nodes {
        __typename
        id
        createdAt
        updatedAt
        comment
        rating
        version
        title
        reviewer {
          displayName
        }
        response {
          comment
          responder {
            displayName
          }
        }
        ... on DealerSalesReview {
          lead {
            vehicle {
              vin
              year
              make
              model
            }
            wasVehiclePurchased
            createdAt
          }
        }
        ... on ServiceReview {
          service {
            vehicle {
              vin
              year
              make
              model
            }
            transactionDate
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}`
                    )
                ]} />
                </p>
                </section>
                <hr/>
                <section>
                <h3 id="respond-to-a-review">Respond to a Review</h3>
                <p>You can respond to a specific review on behalf of a location.
                    A review is identified by its <code>reviewId</code> and <code>version</code>. You
                    can only respond to the most recent version.</p>
                <p>Example query:
                <CodeSnippetTemplate codeSnippets={[
                    new CodeSnippet(CodeSnippetLanguages.GRAPHQL,
                        "GraphQL", `mutation ($review: ReviewOperationInput!, $response: ReviewResponseInput!) {
  review(review: $review) {
    respond(response: $response) {
      review {
        version
      }
    }
  }
}`
                    )
                ]} />
                </p>
                <p>Variables:
                <CodeSnippetTemplate codeSnippets={[
                    new CodeSnippet(CodeSnippetLanguages.JSON,
                        "JSON", `{
    "review": {
        "reviewId": "ODg5MjJkY2RkM2FkYTY3",
        "version": "MC0wMi0wNlQyMTowNzoyM"
    },
    "response": {
        "comment": "Thanks for your feedback.",
        "responderDisplayName": "C. Fox",
        "responderUsername": "carfox@example.com"
    }
}`
                    )
                ]} />
                </p>
                <p><code>comment</code> and <code>responderDisplayName</code> are publicly
                    displayed. <code>responderUsername</code> is
                    not publicly displayed but is used internally to identify the specific individual who
                    authored the response. You can use whatever individual identifier is convenient, such
                    as an e-mail address or user name.</p>
                <p>The <code>respond</code> mutation returns the updated review, from which you can query any
                    of the usual review fields. The example mutation shows how to get the new <code>version</code>
                    after the update.</p>
                    <h5>
                        Updating a Response
                    </h5>
                    <p>To edit/update a response, send another response using the query above with the most recent version. Note that the full response in the Comment field will be updated with what is sent in the request.</p>
                </section>
            </div>
        )
    }

};
