import React, {Component} from 'react';
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import 'react-bootstrap';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Overview from './Overview/Overview';
import './App.scss';
import SendingQueries from "./SendingQueries/SendingQueries";
import Authentication from "./Authentication/Authentication";
import ErrorHandling from "./ErrorHandling/ErrorHandling";
import Pagination from "./Pagination/Pagination";
import Consumer from "./Consumer/Consumer";
import Dealer from "./Dealer/Dealer";
import ReputationManagement from "./ReputationManagement/ReputationManagement";
import {Container} from "react-bootstrap";
import Constants from "./Constants";
import Explorer from "./Explorer/Explorer";
import GettingStartedDealer from "./GettingStartedDealer/GettingStartedDealer";
import GettingStartedConsumer from "./GettingStartedConsumer/GettingStartedConsumer";
import RecentUpdates from "./RecentUpdates/RecentUpdates";
import GettingStartedAuctionPartner from "./GettingStartedAuctionPartnerSellerPaid/GettingStartedAuctionPartner";
import AuctionPartner from "./AuctionPartner/AuctionPartner";
import SSOImplementation from "./SSOImplementation/SSOImplementation";
import Faq from "./Faq/Faq";
import OEMPartner from './OEMPartner/OEMPartner';
import {isIE} from 'react-device-detect';
import CFXLogo from '../assets/images/cfxlogo.svg';
import CFXFox from '../assets/images/circlefox.png';
import RateQuotaLimits from "./RateQuotaLimits/RateQuotaLimits";

export default class App extends Component<any, {}> {

    // placeholder variable that was used for testing
    // will be fixed by PSDVHD-1535
    private readonly accessToken: string = "";
    public state = {
        isAuthenticated: true
    }

    public constructor(props: any) {
        super(props);
    }

    public render() {

        if (isIE) {
            return (App.internetExplorerMessage())
        }

        return (
            <div className={"cd-app"}>
                <BrowserRouter>
                    <Navbar />
                    <Container>
                        <Switch>
                            <Route exact path={[Constants.HOME_PAGE_URL, Constants.HOME_PAGE_INDEX_URL]} component={Overview} />
                            <Route exact path={Constants.SEND_QUERIES_PAGE_URL} component={SendingQueries} />
                            <Route exact path={Constants.AUTHENTICATION_PAGE_URL} component={Authentication} />
                            <Route exact path={Constants.ERROR_HANDLING_PAGE_URL} component={ErrorHandling} />
                            <Route exact path={Constants.PAGINATION_PAGE_URL} component={Pagination} />
                            <Route exact path={Constants.CONSUMER_PAGE_URL} component={Consumer} />
                            <Route exact path={Constants.DEALER_PAGE_URL} component={Dealer} />
                            <Route exact path={Constants.AUCTION_PARTNER_PAGE_URL} component={AuctionPartner} />
                            <Route exact path={Constants.GETTING_STARTED_CONSUMER_URL} component={GettingStartedConsumer} />
                            <Route exact path={Constants.GETTING_STARTED_DEALER_URL} component={GettingStartedDealer} />
                            <Route exact path={Constants.GETTING_STARTED_AUCTION_PARTNER} component={GettingStartedAuctionPartner} />
                            <Route exact path={Constants.REPUTATION_MANAGEMENT_PAGE_URL} component={ReputationManagement} />
                            <Route exact path={Constants.OEM_PARTNER_PAGE_URL} component={OEMPartner} />
                            <Route exact path={Constants.CONNECT_EXPLORER_URL} component={Explorer}/>
                            <Route exact path={Constants.FAQ_URL} component={Faq}/>
                            <Route exact path={Constants.RECENT_UPDATES_URL} component={RecentUpdates}/>
                            <Route exact path={Constants.SSO_IMPLEMENTATION_URL} component={SSOImplementation}/>
                            <Route exact path={Constants.RATE_QUOTA_LIMITS_URL} component={RateQuotaLimits}/>
                        </Switch>
                    </Container>
                </BrowserRouter>
                <Footer/>
            </div>
        );
    }

    private static internetExplorerMessage(): JSX.Element {
        return (
            <div className="ie-message-block">
                <div className="bars-header">
                    <img id="ie-carfax-logo" src={CFXLogo} width="278" height="52" alt="carfax logo"/>
                </div>
                <img id="ie-carfax-bubble" src={CFXFox} alt=""/>

                <h2 className="ie11-message-header-text">
                    Microsoft IE11 is no longer supported by developer.carfax.com
                </h2>

                <p className="ie11-message-paragraph-text">
                    We recommend you switch to one of our supported browsers, such as&nbsp;
                    <a className="ie11-message-anchor" href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a>,&nbsp;
                    <a className="ie11-message-anchor" href="https://www.google.com/chrome/">Google Chrome</a>, or&nbsp;
                    <a className="ie11-message-anchor" href="https://www.mozilla.org/en-US/firefox/new/">Mozilla Firefox</a>
                </p>

                <p className="ie11-message-paragraph-text">
                    <strong
                    >Please contact the Partner Help Desk at&nbsp;
                        <a href={"mailto:partnerhelp@carfax.com"} className="ie11-message-anchor">partnerhelp@carfax.com</a> or call&nbsp;
                        <a href={"tel:571-321-7413"} className="ie11-message-anchor">571-321-7413</a> option 1 if you have questions.</strong
                    >
                </p>
            </div>
        )
    }
}


