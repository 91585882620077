import React, {Component} from 'react';
import './GettingStartedAuctionPartner.scss';
import ContentTemplate from "../ContentTemplate/ContentTemplate";
import {HashLink as Link} from "react-router-hash-link";
import Constants from "../Constants";
import CodeSnippetTemplate from "../CodeSnippetTemplate/CodeSnippetTemplate";
import CodeSnippet from "../../model/CodeSnippet";
import {CodeSnippetLanguages} from "../CodeSnippetTemplate/CodeSnippetLanguages";
import PartnerPortalNewWebApp from "../../assets/images/partner-portal-new-web-app.png";
import {Image} from "react-bootstrap";
import UniversalLoginDealer from "../../assets/images/universal-login-dealer.png";

interface State {

}

interface Props {

}


export default class GettingStartedAuctionPartner extends Component<Props, State> {

    private sideNavigationLinks: Map<string, string> = new Map([
        ["#schema-auth-code-flow","API Schema"],
        ["#prerequisites-auth-code-flow","Prerequisites"],
        ["#step-1-authenticate","Step 1 - Authenticate"],
        ["#step-2-handle-redirect","Step 2 - Handle Redirect"],
        ["#step-3-access-token","Step 3 - Access Token"],
        ["#step-4-graphql-query","Step 4 - GraphQL Query"],
        ["#step-5-send-request","Step 5 - Send Request"],
        ["#auction-partner-seller-paid","Auction Partner Seller Paid Reports"]
    ]);

    private navLinksMap: Map<string, Map<string, string>> = new Map ([
        ["Getting Started", this.sideNavigationLinks]
    ]);

    public render() {
        return (
            <div className={"getting-started-auction-partner"}>
                <ContentTemplate
                    pageTitle="Getting Started"
                    navlinksMap={this.navLinksMap}
                    content={this.getContentHtml()}
                />
            </div>
        )
    }

    private getContentHtml(): JSX.Element {
        return (
            <div className="content-container">
                <h1 id={"api-schema"}>Getting Started - Auction Partner</h1>
                <p>
                    The Auction Partner path of CARFAX Connect is used by dealer facing auction partners to enable CARFAX Report purchases by authenticated users.
                    This guide will outline the steps to make a basic GraphQL query through the Web Application Flow to CARFAX Connect in order to retrieve a Dealer Report link.
                    See the <a href={Constants.AUCTION_PARTNER_PAGE_URL} target={"_blank"} rel={"noopener noreferrer"}> Auction Partner </a> page for more details once you've finished getting started. </p>
                <ul>
                    <li>
                        The partner integrates the CARFAX Connect API into the application that will be used by dealers.
                    </li>
                    <li>
                        The API should be associated with each specific VIN listing with a corresponding "View CARFAX Report" link and CARFAX logo or other agreed upon graphic.
                        See the <Link to={Constants.DEALER_PAGE_URL + "#carfax-branding"}>CARFAX Branding </Link>section for details.
                    </li>
                    <li>
                        When the link/logo is clicked, the partner’s application sends a GraphQL request to CARFAX with all the necessary parameters and headers.
                    </li>
                    <li>
                        CARFAX initiates the logic and then returns the report URL that displays the CARFAX Report in a new tab or window.
                    </li>
                </ul>
                <p>
                    CARFAX Connect is a GraphQL API which is based on the idea of "you get what you request". If
                    you're not familiar with to GraphQL, please refer to&nbsp;
                    <a href={"https://graphql.org/"} target={"_blank"} rel={"noopener noreferrer"}>GraphQL.org</a> for more information.
                </p>

                <section>
                    <h3 id = {"schema-auth-code-flow"}>API Schema</h3>
                        <p>
                        For the purposes of this guide, the following Schema will be used:
                        <br/>
                            <CodeSnippetTemplate codeSnippets={[
                                new CodeSnippet(
                                    CodeSnippetLanguages.GRAPHQL,
                                    "GraphQL",
                                    `extend type Query {
    dealerReport(vin: Vin!): DealerReport
} 

extend type Mutation {
    purchaseDealerReportIfRequired(vin: Vin!): DealerReportPayload
}

type DealerReportPayload {
    dealerReport: DealerReport
}

type DealerReport{
    # Link to the CARFAX report
    carfaxLink: DealerReportLink
    
    # Expiration for the CARFAX report
    expiresAt: Timestamp
    
    # Information about the queried vehicle
    vehicle: Vehicle!
}

type DealerReportLink {
    # URL to the report
    url: Url!
}

type Vehicle {
    vin: Vin!
}

# Vehicle Identification Number; 17 uppercase alphanumeric characters
scalar Vin
 
# An absolute URL, with encoding applied as needed
scalar Url
 
# Instant in time (not local)
type Timestamp {
    # UNIX timestamp; seconds elapsed since the UNIX epoch, 1970-01-01T00:00:00Z, neglecting leap seconds
    unix: Seconds!
 
    # Milliseconds elapsed since the UNIX epoch, 1970-01-01T00:00:00Z, neglecting leap seconds
    unixMillis: Milliseconds!
 
    # Date-time string in RFC 3339/ISO 8601 format, such as '2019-02-01T13:22:33Z'
    rfc: RfcTimestamp!
} 
# RFC 3339 date & time (an ISO 8601 profile) such as '2019-02-01T13:22:33Z'
scalar RfcTimestamp
 
# Integer in the JavaScript safe range -(2^53 - 1) to 2^53 - 1
scalar IntJs
 
# Seconds represented as an IntJs scalar
scalar Seconds
 
# Milliseconds represented as an IntJs scalar
scalar Milliseconds
 
`
                                )
                            ]}/>
                        </p>
                </section>

                <section>
                    <h3 id={"prerequisites-auth-code-flow"}>Prerequisites</h3>
                    <p>
                        For the purposes of this guide, we will create and use the <strong>Web Application</strong> flow.
                        CARFAX Connect follows the OAuth 2.0 authentication process. The <strong>Web Application</strong> flow
                        is equivalent to the <strong>Authorization Code</strong> flow in OAuth 2.0. More
                        information can be found&nbsp;
                        <Link to={Constants.AUTHENTICATION_PAGE_URL + "#authorization-code-flow"}>here</Link>.
                    </p>
                    <p>
                        Log in to the Partner Portal, and click on the "New Application" button at the top-right of the
                        dashboard. Click on "Web Application", and add the Callback URLs for your app. These are
                        endpoints that will handle the redirect done by our authentication endpoint on which the User
                        would enter their credentials on. You can add multiple endpoints separated by a comma(,).
                    </p>
                    <p>
                        <figure>
                            <img
                                src={PartnerPortalNewWebApp}
                                width="647px"
                                alt="Partner Portal: New Web App Screen"
                            />
                            <figcaption>After you have created the Application, the Client ID can be visible on the
                                front-end but the
                                Client Secret <strong>must</strong> be stored in your back-end server.
                            </figcaption>
                        </figure>
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id={"step-1-authenticate"}>Step 1 - Authenticate the User with CARFAX</h3>
                    <p>
                        The Web Application flow is an interactive flow and requires a username & password to
                        authenticate the User. Start off by redirecting the User to the authentication endpoint with the
                        following query parameters:
                    </p>
                    <ul>
                        <li>client_id – This is the Client ID of the Web Application you created in the Prerequisites
                            section.
                        </li>
                        <li>redirect_uri – Add a Callback URI from the ones you specified when creating the Web
                            Application.
                        </li>
                        <li>state – This is a unique randomly generated string to protect against CSRF. We strongly
                            recommend you use cryptographically generated bytes which then would be encoded with Base64
                            to ensure the resulting string is URL safe.
                        </li>
                        <li>response_type – Must be set to "code".</li>
                        <li>audience – Must be set to "https://connect.carfax.com" URL encoded.</li>
                        <li>scope – Must be set to "offline_access".</li>
                    </ul>
                    <p>
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.MARKUP,
                                "URL",
                                `https://auth.carfax.com/authorize
      ?client_id=YOUR_CLIENT_ID
      &redirect_uri=YOUR_CALLBACK
      &state=cryptogenerateduniquestringwithbase64encoding
      &response_type=code
      &audience=https%3A%2F%2Fconnect.carfax.com
      &scope=offline_access`)
                        ]}/>
                    </p>
                    <br/>
                    <p>
                        The User will then be required to enter their username & password:
                        <Image fluid src={UniversalLoginDealer} alt={"Universal login for dealer"}/>
                    </p>
                </section>

                <hr/>
                <section>
                    <h3 id={"step-2-handle-redirect"}>Step 2 - Handle the redirect</h3>
                    <p>
                        After a successful authentication, the CARFAX endpoint will redirect to the callback URI you
                        provided in the initial redirect with the state and code parameters:
                        <ul>
                            <li>This is the CSRF token you provided in the initial request. This could be now used to
                                validate the request to ensure the authentication is genuine. If the validation fails,
                                do not proceed as that could be a potential session hijack.
                            </li>
                            <li>code – This string will be used to send a request to another authentication endpoint to
                                retrieve the Access Token.
                            </li>
                        </ul>
                    </p>
                    <CodeSnippetTemplate codeSnippets={[
                        new CodeSnippet(CodeSnippetLanguages.MARKUP, 'URL', '\n' +
                            'https://myapp.com/handle-auth-redirect\n' +
                            '       ?code=aaaaaaaaaaa\n' +
                            '       &state=cryptogenerateduniquestringwithbase64encoding')
                    ]}/>
                </section>
                <section>
                    <h3 id={"step-3-access-token"}>Step 3 - Get an Access Token</h3>
                    <p>
                        Send a POST request to the Access Token endpoint to get an Access Token. This Access Token will
                        be used to interact with CARFAX Connect. This request must be made from your back-end server as
                        it requires the Client Secret. The body must be form encoded. Below is the cURL version of the
                        request:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.MARKUP, 'cURL',
                                `curl -XPOST
    -H 'Content-Type: application/x-www-form-urlencoded'
    -d 'grant_type=authorization_code&code=aaaaaaaaaaa&redirect_uri=https://myapp.com/handle-auth-redirect&client_id=YOUR_CLIENT_ID&client_secret=YOUR_CLIENT_SECRET' 'https://auth.carfax.com/oauth/token'
`)
                        ]}/>
                    </p>
                    <p>
                        <h6>Response:</h6>

                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.MARKUP, 'cURL',
                                `{
    "access_token": "eyJ0eXAiOiJKV1QiLCJhb...",
    "refresh_token": "MjBlZDA0OGViZjEyZjg0ZmEzNGE3ZjEwNWU0ZTU0M2UK...",
    "scope": "offline_access",
    "expires_in": 86400,
    "token_type": "Bearer"
}`)
                        ]}/>
                    </p>
                    <p>
                        The Web Application flow will also return a Refresh Token which is used to refresh the short-lived Access Tokens.
                        For more information on the Web Application flow, please visit&nbsp;
                        <Link to={Constants.AUTHENTICATION_PAGE_URL + "#web-application-flow"}>Web Application Flow</Link>.
                    </p>
                </section>

                <hr/>
                <section>
                    <h3 id={"step-4-graphql-query"}>Step 4 - Prepare the GraphQL Query</h3>
                    <p>
                        For the purposes of this guide, we will use the following GraphQL query.
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.GRAPHQL, 'GraphQL',
                                `
query {
  dealerReport(vin: "2GNAXYEX7K6158647") {
    carfaxLink {
      url
    }
    expiresAt {
      rfc
    }
  }
}`)
                        ]}/>
                    </p>
                    <p>
                        Convert your GraphQL to a JSON object. You can also use commas(,) or spaces to separate the
                        different types in the Query instead of the newline character(\n). GraphQL is very flexible when
                        it comes to how you want to format the Query if readability is important:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON, 'JSON',
                                JSON.stringify({
                                    "query": "query {dealerReport(vin:\"2GNAXYEX7K6158647\") {carfaxLink{url} expiresAt{rfc}}}"
                                }, null, 4))
                        ]}/>
                        For more information sending GraphQL queries, and what type of Queries CARFAX Connect supports,
                        please refer to&nbsp;
                        <Link to={Constants.SEND_QUERIES_PAGE_URL}>Sending Queries</Link>.
                    </p>
                </section>

                <hr/>
                <section>
                    <h3 id={"step-5-send-request"}>Step 5 - Send a request to CARFAX Connect</h3>
                    <p>
                        Now you are ready to send a request to CARFAX Connect. In the body of the request to CARFAX Connect, write your GraphQL query.
                        Below is the cURL version of the request:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.MARKUP, 'cURL',
                                `curl -XPOST
    -H "Content-type: application/json"
    -H "Authorization: Bearer <access token from Step 3>"
    -d '{"query":"query {\\n  dealerReport(vin:\\"2GNAXYEX7K6158647\\") {\\n    carfaxLink {\\n      url\\n    }\\n    expiresAt {\\n      rfc\\n    }\\n  }\\n}"}' 'https://connect.carfax.com/v1/graphql'
`)
                        ]}/>
                    </p>
                    <p>
                        If the report is available, you will receive the following response. The URL property contains
                        the link to the report:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON, 'JSON',
                                JSON.stringify(
                                    {
                                        "data": {
                                            "dealerReport": {
                                                "carfaxLink": {
                                                    "url": "https://connect.carfax.com/dir?key=OCQo2PLEJaahiR"
                                                },
                                                "expiresAt": {
                                                    "rfc": "2020-09-18T14:29:29.534Z"
                                                }
                                            }
                                        }
                                    }, null, 4
                                ))
                        ]}/>
                    </p>
                    <p>
                        For more information on Dealer Reports, please refer to&nbsp;
                        <Link to={Constants.DEALER_PAGE_URL + "#dealer-reports"}>Dealer Reports</Link>.
                    </p>
                </section>

                <hr/>

                {/*Auction Partner Seller Paid (Client Credentials Flow) */}
                <section>
                    <h3 id="auction-partner-seller-paid">Auction Partner Seller Paid Reports</h3>
                    <p>
                        If you are an Auction Partner who offers the ability to render seller paid reports to dealers on select consignor inventory,
                        please refer to the&nbsp; <Link to={Constants.AUCTION_PARTNER_PAGE_URL + "#seller-paid-reports"}>Auction Partner Seller Paid Reports</Link>.
                        Reports documentation to learn how to query CARFAX Connect.


                    </p>
                </section>

            </div>
        )
    }
}
