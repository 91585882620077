import React from 'react';
import './Footer.scss'
import {ReactComponent as FooterLogo} from "../../assets/images/CARFAX_logo_R_black.svg";
import {Col, Navbar as BootstrapNavbar, Row} from "react-bootstrap";

interface Props {

}

interface State {

}

export default class Footer extends React.Component<Props, State> {

    public render() {
        return (
            <div className={"footer-container "}>
                <div className={"footer-block"}>
                    <Row className="footer-copyright">
                        <BootstrapNavbar.Brand href="http://www.carfax.com"
                                               target="_blank"
                                               rel="noopener noreferrer">
                            <FooterLogo className={"footer-logo"}/>
                        </BootstrapNavbar.Brand>
                        <hr className={"footer-h-line"}/>
                        <Col sm={8}>©2020 CARFAX, Inc</Col>
                        <Col md>
                            <a href="https://www.carfax.com/company/privacy-statement" 
                            target="_blank" 
                            rel="noopener noreferrer">Privacy</a>
                        </Col>
                        <Col md>
                            <a href="https://www.carfax.com/company/terms-of-use\" 
                            target="_blank" 
                            rel="noopener noreferrer">Terms</a>
                        </Col>
                        <Col md>
                            <a href={"mailto:PartnerHelp@carfax.com"}>Contact Us</a>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
