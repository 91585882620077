import React, {Component} from "react";
import "./Pagination.scss";
import ContentTemplate from "../ContentTemplate/ContentTemplate";
import CodeSnippetTemplate from "../CodeSnippetTemplate/CodeSnippetTemplate";
import CodeSnippet from "../../model/CodeSnippet";
import {CodeSnippetLanguages} from "../CodeSnippetTemplate/CodeSnippetLanguages";

export default class Pagination extends Component<any, any> {
    private sideNavigationLinks: Map<string, string> = new Map([
        ["#background", "Background"],
        ["#implementation", "Implementation"],
        ["#example", "Example"],
    ]);

    private navLinksMap: Map<string, Map<string, string>> = new Map ([
        ["Pagination", this.sideNavigationLinks]
    ]);

    public render() {
        return (
            <div className={"pagination-container"}>
                <ContentTemplate
                    pageTitle="Pagination"
                    navlinksMap={this.navLinksMap}
                    content={this.getContentHtml()}
                />
            </div>
        );
    }

    private getContentHtml(): JSX.Element {
        return (
            <div className={"content-container"}>
                <h1>Pagination</h1>
                <section>
                    <h3 id="background">Background</h3>
                    <p>
                        Some fields can return a large number of objects. To ensure the
                        response can be generated quickly and avoid overwhelming the client
                        application the results are broken into pages. Instead of returning an
                        array, such fields return a<em>connection</em> type according to the
                        <a href="https://facebook.github.io/relay/graphql/connections.htm"
                           target="_blank" rel="noopener noreferrer"
                        >
                            {" "}
                            Relay Connections Specification
                        </a>
                        .
                    </p>
                    <p>Connections use some particular terminology:
                        <ul className={"list-group list-group-flush outer"}>
                            <li className={"list-group-item"}>
                                <strong>node</strong> &mdash; object that contains the actual data
                                of interest
                            </li>
                            <li className={"list-group-item"}>
                                <strong>cursor</strong> &mdash; opaque string indicating the
                                position of a node in the overall results
                            </li>
                            <li className={"list-group-item"}>
                                <strong>edge</strong> &mdash; conceptually represents an edge in a
                                graph diagram; it associates a cursor with a node
                            </li>
                        </ul>
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id="implementation">Implementation</h3>
                    <p>
                        Any field that returns a connection has two arguments to control
                        pagination:
                        <ul className={"list-group list-group-flush outer"}>
                            <li className={"list-group-item"}>
                                <code>first</code> &mdash; maximum number of nodes to return on the
                                page
                            </li>
                            <li className={"list-group-item"}>
                                <code>after</code> &mdash; cursor indicating the page range should
                                start <em>after</em> (not including) the node identified by this
                                cursor; for the first page it can be <code>null</code>
                            </li>
                        </ul>
                    </p>
                    <p>Connection types always include three fields:
                        <ul className={"list-group list-group-flush outer"}>
                            <li className={"list-group-item"}>
                                <code>nodes</code> &mdash; array of the nodes on the current page
                            </li>
                            <li className={"list-group-item"}>
                                <code>edges</code> &mdash; array of the edges on the current page;
                                each edge has a <code>node</code> and its corresponding{" "}
                                <code>cursor</code>
                            </li>
                            <li className={"list-group-item"}>
                                <code>pageInfo</code> &mdash; contains four fields:
                                <ul className={"list-group list-group-flush inner"}>
                                    <li className={"list-group-item"}>
                                        <code>startCursor</code> &mdash; cursor of first node on current
                                        page
                                    </li>
                                    <li className={"list-group-item"}>
                                        <code>endCursor</code> &mdash; cursor of last node on current
                                        page
                                    </li>
                                    <li className={"list-group-item"}>
                                        <code>hasNextPage</code> &mdash; indicates whether another page
                                        exists or the end has been reached
                                    </li>
                                    <li className={"list-group-item"}>
                                        <code>hasPreviousPage</code> &mdash; only used for fields that
                                        support backwards pagination
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </p>
                    <p>
                        Usually you do not need <code>edges</code>. It associates a cursor
                        with every node, but usually you only need the last cursor on the
                        page, which you can get from <code>pageInfo.endCursor</code>. The{" "}
                        <code>nodes</code> array provides a more direct way to access the
                        actual data of interest.
                    </p>
                </section>
                <hr/>
                <section>
                    <h3 id="example">Example</h3>
                    <p>
                        Consider the <code>locationsManagedByReputationPartner</code> field as
                        an example. Even if you do not use this field, all connection type
                        fields follow the same pattern. For brevity, we will only query the{" "}
                        <code>name</code> of each location and request only 2 nodes per page.
                    </p>
                    <p>Here is a query for the first page:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.GRAPHQL,
                                "GraphQL", `query ($after: Cursor) {
  viewer {
    locationsManagedByReputationPartner(first: 2, after: $after) {
      nodes {
        name
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
}`
                            )
                        ]}/>
                    </p>
                    <p>Variables for first page:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON", `{
  "after": null
}`
                            )
                        ]}/>
                    </p>
                    <p>Sample result:
                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON", `{
  "data": {
    "viewer": {
      "locationsManagedByReputationPartner": {
        "nodes": [
          {
            "name": "Dealer Name 1"
          },
          {
            "name": "Dealer Name 2"
          }
        ],
        "pageInfo": {
          "hasNextPage": true,
          "endCursor": "UlFTVEdHQjAwMTI="
        }
      }
    }
  }
}`
                            )
                        ]}/>
                    </p>
                    <p>
                        <code>pageInfo.hasNextPage</code> indicates that there is another
                        page. To get it, simply repeat the same query but use{" "}
                        <code>endCursor</code> for the <code>after</code> argument:

                        <CodeSnippetTemplate codeSnippets={[
                            new CodeSnippet(CodeSnippetLanguages.JSON,
                                "JSON", `{
    "after": "UlFTVEdHQjAwMTI="
}`
                            )
                        ]}/>

                    </p>
                    <p>
                        To get all nodes, repeat this process until <code>hasNextPage</code>{" "}
                        is false.
                    </p>
                </section>
            </div>
        );
    }
}
