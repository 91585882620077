import React from 'react';
import './RecentUpdates.scss';
import ContentTemplate from "../ContentTemplate/ContentTemplate";
import {Link} from "react-router-dom";
import Constants from "../Constants";
import {HashLink} from "react-router-hash-link";
import imgAllowedLogoutUrl from "../../assets/images/allowed-logout-url.png";
import imgDealerConfirmationFlow from "../../assets/images/dealer-confirmation-flow.png";
import imgConnectExplorer from "../../assets/images/connect-explorer-2.png";

interface Props {
}

interface State {
}

export default class RecentUpdates extends React.Component<Props, State> {

    private sideNavigationLinks: Map<string, string> = new Map([
        ["#oct-11-2023", "October 11, 2023"],
        ["#aug-9-2023", "August 9, 2023"],
        ["#mar-27-2023", "March 27, 2023"],
        ["#oct-27-2022", "October 27, 2022"],
        ["#oct-12-2022", "October 12, 2022"],
        ["#may-4-2022", "May 4, 2022"],
        ["#feb-3-2022", "February 3, 2022"],
        ["#dec-10-2021", "December 10, 2021"],
        ["#july-23-2021", "July 23, 2021"],
        ["#june-11-2021", "June 11, 2021"],
        ["#june-9-2021", "June 9, 2021"],
        ["#apr-30-2021", "April 30, 2021"],
        ["#apr-28-2021", "April 28, 2021"],
        ["#apr-19-2021", "April 19, 2021"],
        ["#mar-24-2021", "March 24, 2021"],
        ["#mar-12-2021", "March 12, 2021"],
        ["#mar-5-2021", "March 5, 2021"],
        ["#feb-5-2021", "February 5, 2021"],
        ["#jan-21-2021", "January 21, 2021"],
        ["#dec-10-2020", "December 10, 2020"],
        ["#dec-1-2020", "December 1, 2020"],
        ["#nov-10-2020", "November 10, 2020"],
        ["#nov-2-2020", "November 2, 2020"],
        ["#oct-30-2020", "October 30, 2020"],
        ["#oct-15-2020", "October 15, 2020"],
        ["#oct-09-2020", "October 9, 2020"],
        ["#oct-02-2020", "October 2, 2020"],
        ["#sept-18-2020", "September 18, 2020"]
    ]);

    private navLinksMap: Map<string, Map<string, string>> = new Map([
        ["Recent Updates", this.sideNavigationLinks]
    ]);

    public render() {
        return (
            <div className={"release-notes"}>
                <ContentTemplate
                    pageTitle="Recent Updates"
                    navlinksMap={this.navLinksMap}
                    content={this.getContentHtml()}
                />
            </div>
        );
    }

    private getContentHtml(): JSX.Element {
        return (
            <div className={"content-container"}>
                <h1>Recent Updates</h1>
                <section>
                    <h3 id={"oct-11-2023"}>October 11, 2023
                    </h3>
                    <h5>Improvement</h5>
                    <ul>
                        <li>
                            The <HashLink to = {Constants.CONNECT_EXPLORER_URL}> Connect Explorer </HashLink> tool used for testing by dealer facing partners utilizing the Auth Code flow has been updated to a new design that is more user friendly. The same features except for the Copy CURL function may be found in the new design. Just hover over a given button to see the corresponding function.
                            <br/>
                            <img src={imgConnectExplorer} alt="Carfax Connect Explorer"
                                 className={"img-fluid"}/>
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"aug-9-2023"}>August 9, 2023
                    </h3>
                    <h5>New Feature</h5>
                    <ul>
                        <li>
                             To protect server infrastructure from abuse or misuse new<HashLink to = {Constants.RATE_QUOTA_LIMITS_URL}> Rate and Quota Limits </HashLink>have been implemented on CARFAX Connect. These rates may be referenced on the Rate/Quota Limits tab in the Partner Portal.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"mar-27-2023"}>March 27, 2023
                    </h3>
                    <h5>New Feature</h5>
                    <ul>
                        <li>
                            <HashLink to = {Constants.CONSUMER_PAGE_URL + "#for-sale-by-owner"}> For Sale By Owner (FSBO) </HashLink> was added to Connect to allow partners who provide the ability for consumers to list vehicles for sale to include a link to the CARFAX Report adding value to the listings and facilitating quicker sales.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"oct-27-2022"}>October 27, 2022
                    </h3>
                    <h5>New Feature</h5>
                    <ul>
                        <li>
                            <HashLink to = {Constants.AUCTION_PARTNER_PAGE_URL + "#overview"}> Auction Alert</HashLink> was added to Connect to allow auction partners to provide a mechanism for auctions to retrieve mission critical CARFAX information on vehicles that are offered for sale. This information assists the check-in process and improves block announcements for auctions.
                        </li>
                    </ul>
                    <h5>Improvement</h5>
                    <ul>
                        <li>
                            The set of Connect <HashLink to={Constants.ERROR_HANDLING_PAGE_URL + "#error-codes"}>Error Codes</HashLink> was updated to more fully show the array of errors per partner type and product.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"oct-12-2022"}>October 12, 2022
                    </h3>
                    <h5>Improvement</h5>
                    <ul>
                        <li>
                            Added Last Reported Odometer Reading to the <HashLink to = {Constants.DEALER_PAGE_URL + "#dealer-snapshot-for-stickers"}>Dealer Snapshot for Stickers</HashLink> response to better align to online version of Snapshot.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"may-4-2022"}>May 4, 2022
                    </h3>
                    <h5>Improvement</h5>
                        <ul>
                            <li>
                                The list of available data points for <HashLink to={Constants.DEALER_PAGE_URL + "#stoplight-overview"}>Stoplight</HashLink> was updated to show new configuration options for Red or Yellow light.
                            </li>
                        </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"feb-3-2022"}>February 3, 2022</h3>
                    <h5>New Feature</h5>
                    <ul>
                        <li>
                            Added new <HashLink to={Constants.ERROR_HANDLING_PAGE_URL + "#error-codes"}>error codes</HashLink> for reputation partners.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"dec-10-2021"}>December 10, 2021</h3>
                    <h5>New Feature</h5>
                    <ul>
                        <li>
                            Added a <HashLink to={Constants.DEALER_PAGE_URL + "#overview"}>CHBV</HashLink> option to allow partners to fetch CARFAX History-Based Value with
                            no dealer login.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"july-23-2021"}>July 23, 2021</h3>
                    <h5>New Feature</h5>
                    <ul>
                        <li>
                            Added an <HashLink to={Constants.OEM_PARTNER_PAGE_URL}>OEM Partner Type</HashLink> option to outline Connect features available to
                            OEMs who include CARFAX as part of their certified pre-owned program. The initial feature accounts
                            for OEMs who pay for the CARFAX Report on all CPO inventory at the time of certification in their respective dealer facing applications.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"june-11-2021"}>June 11, 2021</h3>
                    <h5>New Feature</h5>
                    <ul>
                        <li>
                            Added the ability for Dealer Partners to directly integrate <HashLink to={Constants.DEALER_PAGE_URL + "#chbv-overview"}>CARFAX History-Based Value</HashLink> in to pricing or trade evaluation tools.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"june-9-2021"}>June 9, 2021</h3>
                    <h5>New Feature</h5>
                    <ul>
                        <li>
                            Added a oneOwner true/false value to the <HashLink to={Constants.CONSUMER_PAGE_URL + "#instant-carfax-report"}>Consumer Partner Instant CARFAX Report</HashLink> query and
                            response examples. This flag may be leveraged in a CARFAX 1-Owner filter on consumer facing
                            sites.
                        </li>
                    </ul>
                    <h5>Fix</h5>
                    <ul>
                        <li>
                            The list of available data points for <HashLink to={Constants.DEALER_PAGE_URL + "#stoplight-overview"}>Stoplight</HashLink> was updated to properly show those that are able to be custom configured for Red or Yellow light.
                        </li>
                    </ul>

                </section>
                <hr/>
                <section>
                    <h3 id={"apr-30-2021"}>April 30, 2021</h3>
                    <h5>Improvement</h5>
                    <ul>
                        <li>
                            <HashLink to={Constants.DEALER_PAGE_URL + "#dealer-snapshot-for-stickers"}>CARFAX Dealer
                                Snapshot for Stickers </HashLink>
                            is now available for partners who support the ability for dealers to produce window
                            stickers.
                            This feature utilizes CARFAX Connect to include Dealer Snapshot on the stickers as a unique
                            way for dealers to carry the CARFAX message on to their vehicles.
                        </li>
                    </ul>

                </section>
                <hr/>
                <section>
                    <h3 id={"apr-28-2021"}>April 28, 2021</h3>
                    <h5>New Feature</h5>
                    <ul>
                        <li>
                            Consumer facing partners using Connect to render
                            <HashLink to={Constants.CONSUMER_PAGE_URL + "#instant-carfax-report"}> Instant CARFAX
                                Reports </HashLink>
                            now should also use the Value Badge Alt Text supplied in the response in order to be
                            compliant with Web
                            Content Accessibility Guidelines.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"apr-19-2021"}>April 19, 2021</h3>
                    <h5>Fix</h5>
                    <ul>
                        <li>
                            Updated size specs for
                            <HashLink to={Constants.CONSUMER_PAGE_URL + "#consumer-snapshot"}> Consumer
                                Snapshot </HashLink>
                            and <HashLink to={Constants.DEALER_PAGE_URL + "#dealer-snapshot"}>Dealer Snapshot</HashLink>.
                        </li>
                    </ul>

                </section>
                <hr/>
                <section>
                    <h3 id={"mar-24-2021"}>March 24, 2021</h3>
                    <h5>New Features</h5>
                    <ul>
                        <li>
                            An <HashLink to={Constants.DEALER_PAGE_URL + "#open-recall-flag"}>Open Recall
                            Flag</HashLink> was added to Connect to allow Advantage dealers using Enhanced partner
                            applications to check for open recalls on retail or acquisition vehicles.
                        </li>
                        <li>
                            A downloadable <HashLink to={Constants.DEALER_PAGE_URL + "#carfax-dealer-faqs"}>Customer
                            Support FAQ</HashLink> document is now available for Dealer partners to reference in
                            addressing possible questions from dealers post launch.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"mar-12-2021"}>March 12, 2021</h3>
                    <h5>New Feature</h5>
                    <ul>
                        <li>
                            <HashLink to={Constants.STOPLIGHT_PAGE_URL}>CARFAX Stoplight</HashLink> was added to Connect
                            to allow for a VIN to be checked against a set of predefined vehicle history criteria to
                            determine
                            if any of the events are present. A Red Light and Yellow Light set of criteria may be
                            independently
                            configured to allow partners to first check for major events and then subsequently moderate
                            events.
                            This enables the Stoplight product to be used in multiple ways such as by dealers or OEMs
                            screening
                            consumer vehicles in a cash offer tool, by dealers flagging risky acquisitions to avoid from
                            auctions, or dealers getting daily updates on possible vehicle history changes on their
                            retail inventory.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"mar-5-2021"}>March 5, 2021</h3>
                    <h5>Improvement</h5>
                    <ul>
                        <li>
                            The <HashLink to={Constants.AUCTION_PARTNER_SELLER_PAID_PAGE_URL}>Auction Partner Seller
                            Paid
                        </HashLink> solution was updated to now allow a CARFAX Report to be run and the report link
                            provided whenever a click occurs on the CARFAX badge associated with seller paid inventory.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"feb-5-2021"}>February 5, 2021</h3>
                    <h5>Improvement</h5>
                    <ul>
                        <li>
                            In response to partner/dealer feedback the cadence to refresh user credentials has been
                            updated from every 30 days to 90 days. A 30 day inactivity window has also been added.
                            Details found on the <HashLink to={Constants.FAQ_URL}>FAQ page</HashLink>.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"jan-21-2021"}>January 21, 2021</h3>
                    <h5>Fix</h5>
                    <ul>
                        <li>
                            One of the benefits of Connect is that by using Single Sign-On a user will not need to sign
                            in multiple times across different partner applications on a device. Prior to this update a
                            user who had logged in on Partner 1 and then navigated to Partner 2 got an odd experience
                            where clicking Log In would result in the screen flashing and no formal prompt to save
                            credentials. The user would be able to run reports as the underlying access token in the
                            user’s session is leveraged but the user was left wondering why it worked without entering
                            credentials nor did it offer an obvious way to log out if the end user had changed.
                            Thus, we have added a new Welcome Back message for this case that asks the user to confirm
                            the saved username to proceed or a path to log out. This screen should only appear to the
                            user on a given device upon initially clicking Log In and potentially again in the future
                            when asked to refresh credentials.
                            <br/>
                            <img src={imgDealerConfirmationFlow} alt="Dealer Confirmation Flow"
                                 className={"img-fluid"}/>
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"dec-10-2020"}>December 10, 2020</h3>
                    <h5>New Features</h5>
                    <ul>
                        <li>
                            New dealer facing <HashLink to={Constants.ERROR_HANDLING_PAGE_URL + "#graphql-errors"}>error
                            messaging</HashLink> is
                            now provided in the GraphQL response which may be shown on screen to users. This allows for
                            more consistent messaging and removes any need to know how to interpret the error.
                        </li>
                        <li>
                            Auction Partners featuring seller paid reports now have the ability to render&nbsp;
                            <HashLink
                                to={Constants.AUCTION_PARTNER_PAGE_URL + "#seller-paid-snapshot"}>Snapshot</HashLink> providing
                            a consistent experience for dealers across all listings.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"dec-1-2020"}>December 1, 2020</h3>
                    <h5>New Features</h5>
                    <ul>
                        <li>
                            Exciting news for Consumer Partners. Consumer version of the&nbsp;
                            <HashLink to={Constants.CONSUMER_PAGE_URL + "#consumer-snapshot"}>Snapshot</HashLink> is
                            now available along with Instant CARFAX Report. This valuable addition to a dealer's site
                            enables consumers to quickly see the key vehicle history points for the given vehicle,
                            allowing them to make an informed buying decision.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"nov-10-2020"}>November 10, 2020</h3>
                    <h5>New Features</h5>
                    <ul>
                        <li>
                            Updated the <Link to={Constants.AUTHENTICATION_PAGE_URL + "#log-out"}>Log Out</Link> feature
                            for Dealer partners to enable the ability to redirect users back to custom landing pages in
                            your application
                            after the user logs out. The landing page URLs may be captured in the new "Allowed Logout
                            URLs" field in the Partner Portal.
                        </li>
                        <img src={imgAllowedLogoutUrl} alt="Allowed Logout Url" width="500" height="320"/>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"nov-2-2020"}>November 2, 2020</h3>
                    <h5>New Features</h5>
                    <ul>
                        <li>
                            Updated <Link to={Constants.FAQ_URL}>FAQ page</Link> to reflect addition of seven new key
                            FAQs including clarity on how to ensure users are authenticated properly and a check list
                            of items needed prior to doing a final demo of the Connect integration with your CARFAX
                            account manager.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"oct-30-2020"}>October 30, 2020</h3>
                    <h5>New Features</h5>
                    <ul>
                        <li>
                            Added an option for <Link to={Constants.CONSUMER_PAGE_URL}>Consumer partners</Link> to send
                            only a VIN for Instant CARFAX Report matching when dealer IDs are not able to be used
                            as agreed to with your CARFAX account manager.
                        </li>
                    </ul>
                    <h5>Fixes</h5>
                    <ul>
                        <li>
                            Deprecated the Audience parameter when fetching a new Access token using the Refresh token
                            to align to OAuth2 format.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"oct-15-2020"}>October 15, 2020</h3>
                    <h5>New Features</h5>
                    <ul>
                        <li>
                            Added <Link to={Constants.SSO_IMPLEMENTATION_URL}>Single Sign-On (SSO)</Link> page to
                            provide clearer direction on how to properly authenticate users and ensure they enjoy the
                            SSO benefit of Connect across partner applications.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"oct-09-2020"}>October 9, 2020</h3>
                    <h5>New Features</h5>
                    <ul>
                        <li>
                            Added <Link to={Constants.FAQ_URL}>FAQ</Link> page with some common questions regarding
                            Carfax Connect.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"oct-02-2020"}>October 2, 2020</h3>
                    <h5>New Features</h5>
                    <ul>
                        <li>
                            Added Recent Updates promo to Overview page to highlight new Recent Updates page.
                        </li>
                        <li>
                            Added Recent Updates page to provide more details for new features, fixes and improvements
                            to Connect.
                        </li>
                        <li>
                            Added <Link to={Constants.GETTING_STARTED_AUCTION_PARTNER}>Getting Started</Link> page for
                            Auction partners to provide clearer direction for developers to set up Connect.
                        </li>
                        <li>
                            Added <Link to={Constants.AUCTION_PARTNER_PAGE_URL}>Auction partner</Link> page with
                            additional details related to features for Auction partners including how to provide CARFAX
                            Reports for seller paid consignors where applicable based on your partnership with CARFAX.
                        </li>
                    </ul>
                </section>
                <hr/>
                <section>
                    <h3 id={"sept-18-2020"}>September 18, 2020</h3>
                    <h5>New Features</h5>
                    <ul>
                        <li>
                            Added <Link to={Constants.GETTING_STARTED_DEALER_URL}>Getting Started</Link> page for Dealer
                            partners to provide clearer direction for developers to set up Connect.
                        </li>
                        <li>
                            Added <Link to={Constants.GETTING_STARTED_CONSUMER_URL}>Getting Started</Link> page for
                            Consumer partners.
                        </li>
                        <li>
                            Added <HashLink to={Constants.DEALER_PAGE_URL + "#connect-caching"}>Caching
                            Guidelines</HashLink> to Dealer partner page to provide suggestions on how to most
                            efficiently fetch and render CARFAX links and data for multiple users at the same
                            dealership.
                        </li>
                    </ul>
                    <h5>Improvements</h5>
                    <ul>
                        <li>
                            Revised the <Link to={Constants.CONNECT_EXPLORER_URL}>Connect Explorer</Link> GraphQL
                            testing tool to be more user friendly to include:
                            <ul>
                                <li>
                                    Better visuals
                                </li>
                                <li>
                                    Visible request headers
                                </li>
                                <li>
                                    Improved syntax highlighting
                                </li>
                                <li>
                                    Customizable interface
                                </li>
                            </ul>
                        </li>
                    </ul>
                </section>
            </div>
        )
    }

}


