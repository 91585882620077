export default class Auth0UserInfo {
    name: string;
    updatedAt: string;
    email: string;
    partnerType: string

    public constructor(name: string, updatedAt: string, email: string, partnerType: string) {
        this.name = name;
        this.updatedAt = updatedAt;
        this.email = email;
        this.partnerType = partnerType
    }
}