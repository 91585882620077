import React, {Component} from 'react';
import './CollapsibleContent.scss';
import Button from "@carfax-stencils/button";
import {ArrowUp, ArrowDown} from "@carfax-stencils/icon";

interface Props {
    content: JSX.Element;
}

interface State {
    isCollapsed: boolean;
}

export default class CollapsibleContent extends Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            isCollapsed: true
        }
    }

    public render() {
        return (
            <div className="collapsible-content-container">
                <div className={`collapsible-content ${this.state.isCollapsed ? "" : "expanded"}`}>
                    {this.props.content}
                    <div className="overlay-container">

                    </div>
                </div>
                <Button className={"expand-collapse-button"} size={"medium"} theme={"blue"}
                        onClick={() => this.expandCollapseButtonClick()}
                        children={
                            this.state.isCollapsed
                            ? <>
                                    View More <ArrowDown size={20} />
                                </>
                            : <>
                                    View Less <ArrowUp size={20} />
                                </>
                        }
                />
            </div>
        )
    }

    private expandCollapseButtonClick() {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        })
    }
}
