import React, {Component} from 'react';
import './Explorer.scss';
import Constants from "../Constants";
import {Provider} from 'react-redux'
import { createGraphiQLFetcher } from '@graphiql/toolkit';
import Button from "@carfax-stencils/button";
import GraphqlAuthentication from './GraphqlAuthentication'
//import Modal from "@carfax-stencils/modal"
import {
    Modal, ModalHeader, ModalBody, ModalFooter
} from '@carfax-stencils/modal'
import InputText from "@carfax-stencils/input-text"
import FormElement from "@carfax-stencils/form-element"
import {
    Notification,
    NotificationIcon,
    NotificationMessage
} from "@carfax-stencils/notification";
import {GraphiQL, GraphiQLProvider} from "graphiql";
import {DOC_EXPLORER_PLUGIN} from "@graphiql/react"
import Message from "@carfax-stencils/form-element/lib/parts/Message";
import {SpinnerDots} from "@carfax-stencils/spinner";
import 'graphiql/graphiql.css';
import DEFAULT_QUERY from "./DefaultQuery";







interface State {
    isModalVisible: boolean
    isAuthenticated: boolean
    partnerClientID: string
    accessToken: string
    explorerClass: string
    isLoading: boolean
    graphqlHeader: any
    isClientIDValid: boolean
    showLoginError: boolean
}

interface Props {
}

/**
 * This is NOT testable on your local machine. The GraphiQL client throws errors because the schema is fetched rather than defined here.
 * Hopefully this will be fixed when graphiql version 2.0 is released, but for now this is something we have to live with.
 */
export default class Explorer extends Component<Props, State> {

    private static readonly CARFAX_CONNECT_URL = Constants.CARFAX_CONNECT_URL_MAP.get(Constants.LEVEL)! + "/v1/graphql"
    private static readonly ONE_DAY = 86400000;
    private graphqlAuthentication : GraphqlAuthentication;

    public constructor(props: Props) {
        super(props)
        this.graphqlAuthentication = new GraphqlAuthentication('');
        this.state = {
            isModalVisible: false,
            partnerClientID: "",
            accessToken: '',
            isAuthenticated: false,
            explorerClass: "logged-out",
            isLoading: true,
            isClientIDValid: true,
            graphqlHeader: { "Authorization": "Bearer "},
            showLoginError: false
        }
    }

    private closeModalOnCancelClick = () => {
        this.setState({
            isModalVisible: false
        })
    }

    private  showModalOnButtonClick = () => {
        this.setState({
            isModalVisible: true
        });
    }

    private handleCarfaxLoginWithClientID = () => {

        if(this.state.isClientIDValid && this.state.partnerClientID) {
            this.graphqlAuthentication = new GraphqlAuthentication(this.state.partnerClientID);
            localStorage.setItem('partnerClientID', this.state.partnerClientID as string)

            this.redirectToUniversalLogin().then( response => {
                return response;
            });
        }
    }

    public async redirectToUniversalLogin() {
        const clientID: string = localStorage.getItem('partnerClientID') as string

        if (clientID !== null && !localStorage.getItem("partnerAccessToken")) {
            await this.graphqlAuthentication.redirectToUniversalLogin()
        }
    }

    public async componentDidMount() {
        const clientID:string = localStorage.getItem("partnerClientID") as string
        const urlQueryParams = window.location.search.substring(1);
        const queryValues = parseParams(urlQueryParams);
        const code: string = queryValues.code as string;
        let isAuthenticated = false;
        let timeNow: number = Date.now();

        if (clientID){
            this.graphqlAuthentication = new GraphqlAuthentication(clientID);
        }

        if(localStorage.getItem("partnerAccessToken")) {
            //the case when the partner comes back after 1 day, local store has expired access token -> we get a new one
            let expirationTime = JSON.parse(localStorage.getItem('accessTokenExpirationTime') || '{}') ;
            let localStorageTime = parseInt(expirationTime);

            isAuthenticated = true;
            if(timeNow > localStorageTime) {
                this.deleteAllCookies();
            }
        }

        if(isAuthenticated && clientID) {
            this.setState({
                partnerClientID: clientID,
                isAuthenticated: true,
                explorerClass: "logged-in",
                isLoading: false,
                graphqlHeader: {
                    "Authorization": "Bearer " + localStorage.getItem("partnerAccessToken")
                }
            })
        }
        else if(code) { //when user has the code & state values after coming back frm redirectToUnv Login
            try {
                await this.graphqlAuthentication.handleAuthentication();
            } catch (error) {
                this.setState({
                    showLoginError : true
                });
            }

            let partnerAccessToken = await this.graphqlAuthentication.getAccessToken();
            let timeNow: number = Date.now() + Explorer.ONE_DAY; //1 day added for expiration of the access token

            localStorage.setItem("partnerAccessToken", partnerAccessToken as string);
            localStorage.setItem("accessTokenExpirationTime", timeNow.toString());

            window.location.replace(Constants.CONNECT_EXPLORER_URL); //removing the code & state values from the URL
        }
        else{
            this.setState({
                isLoading: false
            })
        }
    }

    private deleteAllCookies() {
        localStorage.removeItem('partnerClientID');
        localStorage.removeItem("partnerAccessToken");
        localStorage.removeItem("accessTokenExpirationTime");
        localStorage.removeItem("graphql-playground");
        localStorage.removeItem("graphiql:query");
        localStorage.removeItem("graphiql:tabState")
        localStorage.removeItem("graphiql:variables")

        this.setState({
            partnerClientID: '',
            isAuthenticated: false,
            explorerClass: "logged-out"
        })
    }

    private clientIdValidation(value: string) : boolean {
        return value.length > 29;
    }

    private handleLogout = () => {
        this.deleteAllCookies();
        window.open(this.graphqlAuthentication.redirectToSignOut(),'_blank');
        window.location.reload();
    }

    private getValidationMessage(): string {
        if(!this.state.isClientIDValid) {
            return 'Please enter a valid Single Page Application(SPA) Client ID'
        }
        return '';
    }

    public render() {
        if(this.state.showLoginError){
            return (
                <div className={"error-message"}>
                    <Notification
                        id="error-notification"
                        role={"alert"}
                        scrollIntoView={true}
                        theme={"red"}>
                        <NotificationIcon/>
                        <NotificationMessage>{"Login failed. Please enter a valid Single Page Application(SPA) Client ID"}</NotificationMessage>
                    </Notification>
                </div>
            )
        }
        else if(this.state.isLoading) {
            return (
                <div className={"authenticating-spinner"}>
                    <SpinnerDots>Authenticating</SpinnerDots>
                </div>
            )
        }
        else{
            return (
                <div className={"explorer"}>
                   <div>
                       { this.state.isAuthenticated ?
                           ( <FormElement isInline={true} className={"client-id-label"} ><strong>Client ID: </strong> {this.state.partnerClientID} </FormElement>) : (<FormElement isInline={true} className={"client-id-label"} >Please log in with SPA Client ID to use Connect Explorer</FormElement>)
                       }

                       { this.state.isAuthenticated?
                           (<Button theme="black" onClick={this.handleLogout} className={"sign-in-out-button"} size="medium">Log Out</Button>) : ( <Button theme={'blue-outline'} onClick={this.showModalOnButtonClick} className={"sign-in-out-button"} size="medium">Save your Client ID</Button> )
                       }
                   </div>
                    <Modal className={"modal-popup-container"}
                        isVisible={this.state.isModalVisible}
                        onClose={()=>this.closeModalOnCancelClick()}

                    >
                        <ModalHeader>
                            Enter Your CARFAX Partner SPA Client ID
                        </ModalHeader>

                        <ModalBody>
                                <FormElement>

                                    <InputText name="clientID"
                                               value={this.state.partnerClientID}
                                               onChange={(event) => {
                                                   this.setState({
                                                       partnerClientID: event.target.value,
                                                       isClientIDValid: this.clientIdValidation(event.target.value)
                                                   })
                                               }}
                                               isValid={this.state.isClientIDValid}
                                               placeholder="Client ID for Single Page Application" />
                                    {this.getValidationMessage() && <Message theme={"red"}>{this.getValidationMessage()}</Message>}
                                </FormElement>
                        </ModalBody>



                        <ModalFooter>
                            <div>
                                <Button theme="blue" id="save-btn" onClick={this.handleCarfaxLoginWithClientID} className={"clientId-save-button"} >Continue to Log in as a CARFAX dealer</Button>
                                <Button theme="black" onClick={this.closeModalOnCancelClick}>Cancel</Button>
                            </div>
                        </ModalFooter>
                    </Modal>

                    <div className={this.state.explorerClass + " playground"} >
                            <GraphiQL defaultQuery={DEFAULT_QUERY} headers={JSON.stringify(this.state.graphqlHeader ?? {})} fetcher={createGraphiQLFetcher({ url: Explorer.CARFAX_CONNECT_URL})}  />
                    </div>
                </div>
            );
        }
    }
}
export const parseParams = (string: string) => {
    // Set up a new URLSearchParams object using the string.
    const params = new URLSearchParams(string)

    // Get an iterator for the URLSearchParams object.
    const entries = params.entries()

    const result: any = {}

    // Loop through the URLSearchParams object and add each key/value
    for (const [key, value] of entries) {
        // Split comma-separated values into an array.
        if (value.includes(',')) {
            result[key] = value.split(',')
        } else {
            result[key] = [value]
        }

        // If a key does not have a value, delete it.
        if (!value) {
            delete result[key]
        }
    }

    return result
}
