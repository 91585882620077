import React, {Component} from 'react';
import Highlight, {defaultProps} from "prism-react-renderer";
import theme from "prism-react-renderer/themes/vsDark";
import {Line, LineContent, LineNo, Pre} from "./CodeStyles";
import {Tab, Tabs} from "react-bootstrap"

import './CodeSnippetTemplate.scss';
import CodeSnippet from "../../model/CodeSnippet";

interface Props {
    codeSnippets: CodeSnippet[]
}

interface State {

}

export default class CodeSnippetTemplate extends Component<Props, State> {

    public render() {
        let tabs: JSX.Element[] = [];

        this.props.codeSnippets.forEach(codeSnippet => {
            let language = codeSnippet.getLanguage()
            let languageName = codeSnippet.getLanguageName()
            let thisTabContent = (
                <Tab eventKey={language} title={languageName} key={language}>
                    {this.getCodeSnippet(language, codeSnippet.getCode())}
                </Tab>
            );

            tabs.push(thisTabContent);
        })

        return (
            <div className={"code-snippet-template"}>
                <Tabs id="query-container">{tabs}</Tabs>
            </div>
        );
    }

    private getCodeSnippet(language: string, code: string): JSX.Element {
        return (
            <Highlight{...defaultProps} theme={theme} code={code}
                language={language as any} // there appears to be an implicit cast to type Language when a constant string isused
            >
                {({className, style, tokens, getLineProps, getTokenProps}) => (
                    <Pre className={className} style={style}>
                        {tokens.map((line, i) => (
                            <Line key={i} {...getLineProps({line, key: i})}>
                                <LineNo>{i + 1}</LineNo>
                                <LineContent>
                                    {line.map((token, key) => (
                                        <span key={key} {...getTokenProps({token, key})} />
                                    ))}
                                </LineContent>
                            </Line>
                        ))}
                    </Pre>
                )}
            </Highlight>
        );
    }
}
