import React, {Component} from "react";
import './SSOImplementation.scss';
import ssoImplementation from "../../assets/images/sso-implementation.png";
import ContentTemplate from "../ContentTemplate/ContentTemplate";

export default class SSOImplementation extends Component<any, any> {
    private sideNavigationLinks: Map<string, string> = new Map([
        ["#overview", "Overview"],
        ["#user-experience", "User Experience"]
    ]);

    private navLinksMap: Map<string, Map<string, string>> = new Map ([
        ["Single Sign-On (SSO)", this.sideNavigationLinks]
    ]);

    public render() {
        return (
            <div className={"pagination-container"}>
                <ContentTemplate
                    pageTitle="Single Sign-On (SSO)"
                    navlinksMap={this.navLinksMap}
                    content={this.getContentHtml()}
                />
            </div>
        );
    }

    private getContentHtml(): JSX.Element {
        return (
            <div className={"content-container"}>
                <h1>Single Sign-On (SSO)</h1>
                <section>
                    <h3 id="overview">Overview</h3>
                    <p>
                        A consistent dealer user experience across multiple partners applications is one of the key
                        goals of CARFAX Connect. Single sign&#8209;on is a key feature of CARFAX Connect that reduces
                        the need for a user to sign in multiple times across different partner applications on one
                        device. To achieve Single sign&#8209;on, you will need to take care of a few things while
                        implementing CARFAX Connect authentication. This page outlines an ideal user experience and
                        provides suggestions on how to implement Single sign&#8209;on.
                    </p>
                <hr/>
                </section>
                <section>
                    <h3 id={"user-experience"}>User Experience</h3>
                    <p>
                        The following steps outline the high-level interaction between the partner application and
                        CARFAX Connect to ensure Single sign&#8209;on works across all partner applications on a single
                        device.
                    </p>
                    <p>
                        <ol>
                            <li>
                                User logs into the partner application which has implemented CARFAX Connect.
                            </li>
                            <li>
                                Partner application redirects the user to the CARFAX Connect login screen. The user
                                enters their email username and password.
                            </li>
                            <li>
                                The CARFAX login page redirects the user back to the partner application with code and
                                state values.
                            </li>
                            <li>
                                The partner application then makes a second request to &nbsp;
                                <code>https://auth.carfax.com/oauth/token</code> &nbsp;
                                to get both an access token and a refresh token using that code.
                            </li>
                            <li>
                                If the partner application needs to refresh CARFAX 4 Pillar data nightly, access token
                                and refresh tokens may be stored in a secure database. Only one access
                                token needs to be stored per dealership, as that is all that is needed to enable the
                                refresh of data across the dealership. The access token and refresh tokens should be
                                overwritten every time someone from that dealership logs in or refreshes their access
                                token. This minimizes the risk of the tokens not being valid for any nightly updates.
                            </li>
                            <li>
                                The partner application then stores the access token along with its expiration time in
                                the user's current web server session with the partner application or in a Secure; Http-only cookie.
                                <ol>
                                    <li>
                                        In the case of a traditional web-application, the partner's web server would
                                        continue to maintain this session until the user decides to log out or clear
                                        their cookies which in turn would destroy the session on the web server.
                                    </li>
                                    <li>
                                        In the case of mobile applications, the access token can be sent back along with the other
                                        partner information and be stored in a secure place on the mobile device's
                                        storage (keychain).
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <p>
                            The partner application would then use the access token in the user's session to determine
                            if they're logged in. If the access token has expired, as per the expiration time, then the
                            partner application would need to utilize the refresh token to obtain a new access token
                            from CARFAX Connect.
                        </p>
                    </p>
                    <h5>Notes:</h5>
                    <p>
                        <ol>
                            <li>
                                You should never use the access token in the database to determine if a user is
                                authenticated. This will only enable SSO within your own ecosystem (on applications
                                owned by you). We want to enable SSO across distinct partner applications which requires
                                a means of determining if the user is authenticated to be device dependent. In order to
                                do that, the partner application should use the access token stored in a user's session to
                                determine if a user is logged in or not.
                            </li>
                            <li>
                                If a partner application needs to refresh data nightly, you would need to implement
                                logic to store the access token in the database as well as the one in the user's session
                                in sync. The logic would need to be directly implemented in the web application when the
                                user is interacting with it, as there is no other way to reference a specific session
                                without some event on the user's side.
                            </li>
                        </ol>
                    </p>
                    <p>
                        The diagram below reflects the suggested implementation of the authentication process:
                        <br/>
                        <img
                            alt="SSO Implementation"
                            src={ssoImplementation}
                        />
                    </p>
                </section>
            </div>
        );
    }
}
